import { ReactElement } from 'react';
import { Active } from 'src/components/pages/Users/Active/Active';
import IndexTabs from 'src/components/pages/Users/Index/IndexTabs';

ActiveRoute.route = 'active';

export default function ActiveRoute(): ReactElement {
  return (
    <>
      <IndexTabs />
      <Active />
    </>
  );
}
