import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAxios from 'axios-hooks';
import moment from 'moment';
import { TransactionTableRowProps } from 'src/components/pages/Users/common/common';
import { EshopNameColumn } from 'src/components/pages/Users/common/EshopNameColumn';
import { SwitchState } from 'src/components/pages/Users/common/SwitchState';
import { ServiceState } from 'src/consts/ServiceState';

export const NotApprovedRow: React.FC<TransactionTableRowProps> = ({
  installation,
}: TransactionTableRowProps): JSX.Element => {
  const { t } = useTranslation('users');
  const [creditCheckUrl, setCreditCheckUrl] = useState<string | null>(null);

  const [{ data: creditCheckData }, postCreditCheckUrl] = useAxios<{
    url: string;
  }>(
    {
      url: 'v1/credit-check-url',
      method: 'post',
      data: {
        eshopId: installation.eshopId,
      },
    },
    { manual: true },
  );

  useEffect(() => {
    if (creditCheckData && !creditCheckUrl) {
      setCreditCheckUrl(creditCheckData.url);
      window.open(creditCheckData.url, '_blank');
    }
  }, [creditCheckData, creditCheckUrl]);

  const onCreditCheckClick = async (): Promise<void> => {
    if (!creditCheckUrl) {
      await postCreditCheckUrl();
    }

    if (creditCheckUrl) {
      window.open(creditCheckUrl, '_blank');
    }
  };

  return (
    <tr>
      <EshopNameColumn installation={installation} />
      <td>{moment(installation.updatedAt).format('DD.MM.YYYY HH:mm')}</td>
      <td>{installation.eshopInternals}</td>
      <td>{t(`table.common.services.${installation.service}`)}</td>
      <td>{installation.contactName}</td>
      <td>
        <strong>TODO:</strong> Tariff
      </td>
      <td>
        <span>{t(`state.${installation.state}`)}</span>
      </td>
      <td>
        <button
          style={{ marginBottom: '5px' }}
          title={t('table.actions.creditCheck')}
          className="btn btn-md btn-default"
          onClick={onCreditCheckClick}
        >
          {t('table.actions.creditCheck')}
        </button>

        <SwitchState
          options={installation.allowedStates as ServiceState[]}
          installation={installation}
        />
      </td>
    </tr>
  );
};
