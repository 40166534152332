export enum ServiceState {
  NOT_ORDERED = 'NOT_ORDERED',
  SHOPTET_APPROVAL = 'SHOPTET_APPROVAL',
  SHOPTET_ON_HOLD = 'SHOPTET_ON_HOLD',
  SHOPTET_BAN = 'SHOPTET_BAN',
  ADYEN_APPROVAL = 'ADYEN_APPROVAL',
  ADYEN_ACTIVATION = 'ADYEN_ACTIVATION',
  SHOPTET_PCI = 'SHOPTET_PCI',
  ADYEN_CREATE_STORE = 'ADYEN_CREATE_STORE',
  ADYEN_PAYMENT_METHOD = 'ADYEN_PAYMENT_METHOD',
  SHOPTET_PAYMENT_METHOD = 'SHOPTET_PAYMENT_METHOD',
  ACTIVE = 'ACTIVE',
  SUSPEND = 'SUSPEND',
  ADYEN_ERROR = 'ADYEN_ERROR',
  SHOPTET_ERROR = 'SHOPTET_ERROR',
}
