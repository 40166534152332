import useAxios from 'axios-hooks';

export function useCurrencies(): {
  currencies: CurrencyResponse['currencies'];
  loading: boolean;
} {
  const [{ data: getCurrenciesData, loading: getLoading }] =
    useAxios<CurrencyResponse>({
      url: 'v1/list/currencies',
      method: 'GET',
    });

  return {
    currencies: getCurrenciesData?.currencies ?? [],
    loading: getLoading,
  };
}
