import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as languagesModule from './lang';
import moment from 'moment';
import 'moment/locale/cs';
import 'moment/locale/en-gb';
import { Pages } from 'src/consts/Pages';

const resources = { ...languagesModule } as Resource;
export const fallbackLocale = 'en';
export const knownLanguages = Object.keys(resources);

type EnglishResources = (typeof languagesModule)[typeof fallbackLocale];
declare module 'react-i18next' {
  // need to redeclare original interface for typehint
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Resources extends EnglishResources {}
}

i18n.use(initReactI18next);

// We use global i18n instance, so it can't be initialized multiple times.
// Strict Mode in React 18 mounts components twice, so we need a lock.
let initializeLock = null as null | Promise<void>;
export async function initI18n(): Promise<void> {
  if (initializeLock) {
    await initializeLock;
    return;
  }
  let unlock;
  initializeLock = new Promise(resolve => {
    unlock = resolve;
  });

  await i18n.init({
    fallbackLng: fallbackLocale,
    ns: knownLanguages,
    appendNamespaceToCIMode: true,
    resources,
    interpolation: {
      escapeValue: false,
      format: (value: any, format?: string): string =>
        value instanceof Date ? moment(value).format(format) : <string>value,
    },
  });

  unlock();
}

export function payCrmPageTranslations(language: string, page: Pages): any {
  return languagesModule[language]?.routes?.payCrm?.[page];
}

export function payCrmPageRouteTranslations(
  language: string,
  page: Pages,
  route: string,
): any {
  return payCrmPageTranslations(language, page)?.routes?.[route];
}
