import { ApiClient } from 'src/services/api';
import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import logger from 'src/services/logger';
import { AxiosResponse } from 'axios';
import { Application } from 'src/models';
import { useAppState } from 'src/components/appState';
import { configure as configureAxiosHooks } from 'axios-hooks';

export interface ContextValue {
  client: ApiClient;
}

export const Context = createContext<ContextValue>(undefined as any);

export default function ApiProvider({
  defaultClient,
  state,
  update,
  children,
}: {
  defaultClient: ApiClient;
  state: Application.State;
  update: Application.Update;
  children: ReactNode;
}): ReactElement {
  // only once
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValue = useMemo(() => ({ client: defaultClient }), []);
  const [value, setValue] = useState(initialValue);

  const updateApiClientState = useCallback(
    (phase: Application.BootPhase): void => {
      update(state => ({
        ...state,
        stack: Application.mutations.updateStackPhase({
          ...state.stack,
          apiClient: phase,
        }),
      }));
    },
    [update],
  );

  const setGlobalRequestFailed = useCallback((): void => {
    update(state => ({
      ...state,
      flags: {
        ...state.flags,
        isFailedGlobalRequest: true,
      },
    }));
  }, [update]);

  useEffect(() => {
    connectClient({
      client: value.client,
      initialState: state,
      onSuccess() {
        setValue({ client: value.client });
        updateApiClientState(Application.BootPhase.UP);
        configureAxiosHooks({
          axios: value.client.axios,
          defaultOptions: { ssr: false, useCache: false },
        });
      },
      onFail(message: string) {
        logger.log(`STP:API: connection fail. ${message}`);
        updateApiClientState(Application.BootPhase.DOWN);
      },
      onGlobalFailIntercepted(response: AxiosResponse) {
        logger.log(`STP:API: global request failed. ${response.config.url}`);
        setGlobalRequestFailed();
      },
    }).finally();
    // only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

ApiProvider.Test = function Test({
  defaultClient,
  children,
}: {
  defaultClient: ApiClient;
  children: ReactNode;
}): ReactElement {
  const [state, update] = useAppState();
  return (
    <ApiProvider defaultClient={defaultClient} state={state} update={update}>
      {state.stack.apiClient === Application.BootPhase.UP
        ? children
        : undefined}
    </ApiProvider>
  );
};

export function useApi(): ContextValue {
  const context = useContext(Context);
  // can be empty if provider is not in component tree
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!context) {
    throw new Error('STP: api not provided');
  }
  return context;
}

async function connectClient({
  client,
  initialState,
  onSuccess,
  onFail,
  onGlobalFailIntercepted,
}: {
  client: ApiClient;
  initialState: Application.State;
  onSuccess(): void;
  onFail?(message: string): void;
  onGlobalFailIntercepted?(response: AxiosResponse): void;
}): Promise<void> {
  if (client.connected) {
    onSuccess();
    return;
  }

  try {
    if (!client.axios.defaults.baseURL) {
      onFail?.('client baseURL is empty (config apiBaseURL)');
      return;
    }

    const authCode = initialState.settings.authCode;
    if (!authCode) {
      onFail?.('missing authCode');
      return;
    }

    const connectionOptions = {
      authCode,
      onReconnectionFailed(): void {
        onFail?.('reconnection failed');
      },
      onGlobalFailIntercepted(response: AxiosResponse): void {
        onGlobalFailIntercepted?.(response);
      },
    };
    await ApiClient.connect(client, connectionOptions);

    onSuccess();
  } catch (error: any) {
    onFail?.(error.message);
    logger.error(error);
  }
}
