import { ReactElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultFilterShape } from 'src/components/pages/Users/common/common';
import { UsersFilterForm } from 'src/components/pages/Users/common/UsersFilterForm';
import { useUrlParamsQuery } from 'src/components/RecordsTable/hooks';
import RecordsTable, { Query } from 'src/components/RecordsTable/RecordsTable';
import { ServiceState } from 'src/consts/ServiceState';
import { InstallationsProvider } from 'src/contexts/InstallationsContext';
import { useInstallations } from 'src/services/hooks/useInstallations';
import * as Yup from 'yup';

interface FilterableTableProps {
  states: ServiceState[];
  content(renderContentRow: Installation): ReactElement;
  showState?: boolean;
  showAction?: boolean;
  showUpdateDate?: boolean;
  showActivationDate?: boolean;
  defaultSort?: string;
}

export default function StateTable({
  states,
  content,
  showState = true,
  showAction = true,
  showUpdateDate = true,
  showActivationDate = false,
  defaultSort = 'updatedAt',
}: FilterableTableProps): ReactElement {
  const { t } = useTranslation('users');

  const searchFiltersSchema = Yup.object().shape(
    defaultFilterShape(states.join(',')),
  );

  const buildInitialQuery = (params: Record<string, string>): Query => ({
    ...searchFiltersSchema.getDefaultFromShape(),
    sort: defaultSort,
    ...params,
  });

  const [query, params, setQuery] = useUrlParamsQuery(buildInitialQuery);
  const { listing, onQuery } = useInstallations();

  const refetchData = useCallback(() => {
    onQuery(params);
  }, [onQuery, params]);

  useEffect(refetchData, [refetchData]);

  const headers = [
    { label: t('table.common.name'), orderKey: 'eshopUrl' },
    { label: t('table.common.eshopInternals') },
    { label: t('table.common.service') },
    { label: t('table.common.contact'), orderKey: 'contactName' },
    { label: t('table.common.tariff') },
  ];

  if (showState) {
    headers.push({ label: t('table.common.state') });
  }

  if (showAction) {
    headers.push({ label: t('table.common.action') });
  }

  if (showUpdateDate) {
    headers.splice(1, 0, {
      label: t('table.common.updateDate'),
      orderKey: 'updatedAt',
    });
  }

  if (showActivationDate) {
    headers.splice(
      1,
      0,
      { label: t('table.common.applicationDate'), orderKey: 'applicationDate' },
      { label: t('table.common.activationDate'), orderKey: 'activationDate' },
    );
  }

  const filterState = [{ id: states.join(','), value: '' }];
  filterState.push(
    ...states.map(item => ({
      id: item,
      value: t(`state.${item}`),
    })),
  );

  return (
    <InstallationsProvider value={{ refetchData }}>
      <RecordsTable<Installation>
        queryResult={listing}
        renderRow={content}
        filtersForm={
          <UsersFilterForm
            stateOptions={states.length > 1 ? filterState : undefined}
          />
        }
        headers={headers}
        query={query}
        filtersSchema={searchFiltersSchema}
        onQueryChange={setQuery}
      />
    </InstallationsProvider>
  );
}
