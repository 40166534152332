import { ReactElement } from 'react';
import StackDown from 'src/components/Application/Content/StackDown';
import StackLoading from 'src/components/Application/Content/StackLoading';
import Page from 'src/components/Application/Router/Page';
import Stack from 'src/components/Application/Stack';
import configuration from 'src/configuration';
import { Application } from 'src/models';
import { AppStateProvider } from 'src/components/appState';

export default function Root({
  initialState,
}: {
  initialState: Application.State;
}): ReactElement {
  return (
    <>
      {configuration.isFlagRibbon && (
        <span className="crm-ribbon">
          <span>Lab</span>
        </span>
      )}
      <AppStateProvider defaultState={initialState}>
        <Stack renderContent={renderContent} renderFallback={renderFallback} />
      </AppStateProvider>
    </>
  );
}

function renderContent(): ReactElement {
  return <Page />;
}

function renderFallback(stack: Application.State['stack']): ReactElement {
  if (stack.state === Application.BootPhase.INITIAL) {
    return <StackLoading />;
  } else {
    return <StackDown stack={stack} />;
  }
}
