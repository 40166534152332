import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useAxios from 'axios-hooks';
import { TransactionTableRowProps } from 'src/components/pages/Users/common/common';
import { EshopNameColumn } from 'src/components/pages/Users/common/EshopNameColumn';
import { SwitchState } from 'src/components/pages/Users/common/SwitchState';
import { Tooltip } from 'src/components/shared/primitive/Tooltip';
import { ServiceState } from 'src/consts/ServiceState';

export const ProcessingRow: React.FC<TransactionTableRowProps> = ({
  installation,
}: TransactionTableRowProps): JSX.Element => {
  const { t } = useTranslation('users');
  const [pciUrl, setPciUrl] = useState<string | null>(null);

  const [{ data: pciUrlData }, fetchPciUrl] = useAxios<{
    redirectUrl: string;
  }>(
    {
      url: `v1/${installation.service}/${installation.id}/adyen/pci-questionnaire-url`,
      method: 'post',
      data: {
        returnUrl: window.location.href,
      },
    },
    { manual: true },
  );

  useEffect(() => {
    if (pciUrlData && !pciUrl) {
      setPciUrl(pciUrlData.redirectUrl);
      window.open(pciUrlData.redirectUrl, '_blank');
    }
  }, [pciUrlData, pciUrl]);

  const onClick = async (): Promise<void> => {
    if (!pciUrl) {
      await fetchPciUrl();
    }

    if (pciUrl) {
      window.open(pciUrl, '_blank');
    }
  };

  return (
    <tr>
      <EshopNameColumn installation={installation} />
      <td>{moment(installation.updatedAt).format('DD.MM.YYYY HH:mm')}</td>
      <td>{installation.eshopInternals}</td>
      <td>{t(`table.common.services.${installation.service}`)}</td>
      <td>{installation.contactName}</td>
      <td>
        <strong>TODO:</strong> Tariff
      </td>
      <td>
        <AdyenStates
          state={installation.state}
          errorDescription={installation.errorDescription}
        />
      </td>
      <td>
        {installation.state === ServiceState.SHOPTET_PCI && (
          <button
            title={t('table.processing.pciQuestionnaire')}
            className="btn btn-md btn-default"
            onClick={onClick}
          >
            {t('table.processing.pciQuestionnaire')}
          </button>
        )}
        <SwitchState
          options={installation.allowedStates as ServiceState[]}
          installation={installation}
        />
      </td>
    </tr>
  );
};

function AdyenStates({
  state,
  errorDescription,
}: {
  state: string;
  errorDescription?: string;
}): ReactElement {
  const { t } = useTranslation('users');
  return (
    <>
      <span>{t(`state.${state}`)}</span>
      {[
        ServiceState.ADYEN_ERROR,
        ServiceState.SHOPTET_ERROR,
        ServiceState.SHOPTET_PAYMENT_METHOD,
      ].some((v: ServiceState) => state.includes(v)) &&
        errorDescription && <Tooltip innerTooltipContent={errorDescription} />}
    </>
  );
}
