import MockAdapter from 'axios-mock-adapter';

export default function mockPos(mockApi: MockAdapter): void {
  mockApi.onGet('v1/pos/terminals').reply(() => [
    400,
    {
      items: [
        {
          id: '137327616749',
          type: 'S1F2',
          state: 'ASSIGNED',
          poiId: 'S1F2-000158213600435',
          status: 'OFFLINE',
          defaultForCashDeskIds: [],
          name: 'SnowlyCode',
          price: '299',
          currency: 'CZK',
          personalDelivery: true,
          printReceipt: true,
          printTaxReceipt: false,
          cashDeskIds: [5, 7],
          store: {
            id: '973056781095',
            name: 'KOTELNA Praha',
            storeId: '4109d714-4d34-4f0d-bac5-b9517eeac4df',
            mcc: '5732',
            address: {
              city: 'Praha',
              country: 'CZ',
              houseNumberOrName: '45',
              postalCode: '98383',
              street: 'Pražská',
              phone: '+420777555444',
            },
            installation: {
              id: 'b1271b67-4453-48a4-95bd-8c2a2e831640',
              state: 'ACTIVE',
              eshopId: 416761,
              eshopInternals: 'cs',
              billingCompany: 'SnowlyCode, s.r.o.',
              contactName: 'Ondřej Kubíček',
            },
          },
        },
        {
          id: '578292438768',
          type: 'AMS1',
          state: 'BE_ASSIGNED',
          poiId: null,
          status: 'UNKNOWN',
          defaultForCashDeskIds: [],
          name: '',
          price: '249',
          currency: 'CZK',
          personalDelivery: false,
          printReceipt: false,
          printTaxReceipt: false,
          cashDeskIds: [],
          store: {
            id: '418092524710',
            name: 'test',
            storeId: null,
            mcc: '5331',
            address: {
              phone: '+420737392173',
              street: 'Jégého',
              houseNumberOrName: '17',
              postalCode: '17000',
              city: 'Praha',
              country: 'CZ',
              name: 'test',
            },
            installation: {
              id: 'c9a815b2-65b2-4c32-a719-e0a5e7457b25',
              state: 'ACTIVE',
              eshopId: 464040,
              eshopInternals: 'cs',
              billingCompany: 'Ondřej Buben',
              contactName: 'Mr. Cimrman',
            },
          },
        },
      ],
    },
  ]);

  mockApi
    .onPost(new RegExp('v1/pos/terminals/(.*)/unassign'))
    .reply(() => [500, {}]);
}
