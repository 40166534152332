import MockAdapter from 'axios-mock-adapter';

export default function mock(mockApi: MockAdapter): void {
  mockApi.onPost(/v1\/card\/.{12}\/adyen\/pci-questionnaire-url/).reply(() => [
    200,
    {
      redirectUrl:
        'https://docs.adyen.com/api-explorer/#/Hop/v6/post/getPciQuestionnaireUrl',
    },
  ]);
}
