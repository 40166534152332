import { Application } from 'src/models';
import { Pages } from 'src/consts/Pages';

export default class Router {
  constructor(
    readonly config: Application.Router.Configuration,
    readonly route: undefined | Application.Router.RouteState,
    readonly locale: string,
    readonly update: Application.Update,
  ) {}

  goToRoute(
    page: undefined | Pages,
    route: string,
    params?: Application.Router.Params,
  ): void {
    const nextRouteState = this.nextRouteState(page, route, params);
    const path = this.config.getFullPathForRoute(nextRouteState, this.locale);
    if (page === this.route?.page) {
      Application.Router.getHistory().pushState({}, '', path);
      this.update(state => ({
        ...state,
        route: nextRouteState,
      }));
    } else {
      // TODO: 1634 remove this branch
      Application.Router.helpers.redirectToExternalUrl(path);
    }
  }

  goToSamePageRoute(route: string, params?: Application.Router.Params): void {
    this.goToRoute(undefined, route, params);
  }

  replaceRoute(
    page: undefined | Pages,
    route: string,
    params?: Application.Router.Params,
  ): void {
    const nextRouteState = this.nextRouteState(page, route, params);
    if (!page || page === this.route?.page) {
      this.update(state => ({
        ...state,
        route: nextRouteState,
      }));
    } else {
      // TODO: 1634 remove this branch
      const path = this.config.getFullPathForRoute(nextRouteState, this.locale);
      Application.Router.helpers.redirectToExternalUrl(path);
    }
  }

  nextRouteState(
    page: undefined | Pages,
    route: string,
    params?: Application.Router.Params,
  ): Application.Router.RouteState {
    return {
      page: page ?? Application.Router.assureRoutePresence(this.route).page,
      route,
      params,
    };
  }
}
