import { ReactElement } from 'react';
import { Ban } from 'src/components/pages/Users/Ban/Ban';
import IndexTabs from 'src/components/pages/Users/Index/IndexTabs';

BannedRoute.route = 'banned';

export default function BannedRoute(): ReactElement {
  return (
    <>
      <IndexTabs />
      <Ban />
    </>
  );
}
