import { StrictMode } from 'react';
import configuration from './configuration';
import { Application } from './models';
import Root from './components/Application/Root';
import { spt } from '@shoptet-pay/fe-toolkit';
import { createRoot } from 'react-dom/client';

import 'src/styles/index.scss';

interface Settings {
  code: string;
  language?: string;
}

const window = spt.browser.lib.window;
spt.logger.lib.log = window.console.log.bind(window.console);
spt.logger.lib.error = window.console.error.bind(window.console);

const root = document.getElementById(configuration.rootId);
if (!root) {
  spt.logger.trace('CONTAINER_NOT_FOUND', {
    rootId: configuration.rootId,
  });
  throw new Error(`STP: container #${configuration.rootId} not found`);
}

function buildState(): Application.State {
  const defaultValue = Application.defaultState();

  let settings: Settings | undefined = undefined;
  if (root?.dataset.settings) {
    try {
      settings = JSON.parse(root.dataset.settings);
    } catch {
      throw `STP: container #${configuration.rootId} parse dataset settings error!`;
    }
  }
  return {
    ...defaultValue,
    settings: {
      ...defaultValue.settings,
      locale: settings?.language ?? defaultValue.settings.locale,
      authCode: settings?.code,
    },
  };
}

document.body.classList.add('spt--body');
root.classList.add('spt--container');
const reactRoot = createRoot(root);
reactRoot.render(
  <StrictMode>
    <Root initialState={buildState()} />
  </StrictMode>,
);
