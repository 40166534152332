const URL_PARAMS = 'urlParams';

export function storageUrlKeyGenerator(customHash?: string): string {
  const location = window.location;
  return `${location.pathname || ''}${
    location.pathname.endsWith('/') ? '' : '/'
  }${customHash ? customHash : location.hash || ''}`;
}

function findParamsByUrl(
  urlParams: Record<string, Record<string, string>>,
  storageUrlKey: string,
): Record<string, string> {
  const foundURLKey = Object.keys(urlParams).length
    ? Object.keys(urlParams).find((url: string) => url === storageUrlKey)
    : null;
  return foundURLKey ? urlParams[foundURLKey] : {};
}

function parsedUrlParams(
  urlParams: string | null,
): Record<string, Record<string, string>> {
  return urlParams
    ? <Record<string, Record<string, string>>>JSON.parse(urlParams)
    : {};
}

export function getUrlParams(storageUrlKey: string): Record<string, string> {
  return findParamsByUrl(
    parsedUrlParams(localStorage.getItem(URL_PARAMS)),
    storageUrlKey,
  );
}

export function setUrlParams<T>(newUrlParams: T, storageUrlKey: string): void {
  localStorage.setItem(
    URL_PARAMS,
    JSON.stringify({
      ...parsedUrlParams(localStorage.getItem(URL_PARAMS)),
      [storageUrlKey]: newUrlParams,
    }),
  );
}
