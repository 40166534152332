import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { ObjectShape } from 'yup/lib/object';
import * as Yup from 'yup';

export interface TransactionTableRowProps {
  installation: Installation;
  refetchData?(config?: AxiosRequestConfig): AxiosPromise<Installations>;
}

export const defaultFilterShape = (defaultState: string): ObjectShape => ({
  eshopId: Yup.string().default(''),
  billingCompany: Yup.string().default(''),
  contactName: Yup.string().default(''),
  state: Yup.string().default(defaultState),
  service: Yup.string().default(''),
  eshopInternals: Yup.string().default(''),
});
