import { Application } from 'src/models';

export function updateStackPhase(
  state: Application.State['stack'],
): Application.State['stack'] {
  if (
    state.localization === Application.BootPhase.DOWN ||
    state.apiClient === Application.BootPhase.DOWN ||
    state.router.state === Application.BootPhase.DOWN
  ) {
    return {
      ...state,
      state: Application.BootPhase.DOWN,
    };
  }

  if (
    state.localization === Application.BootPhase.UP &&
    state.apiClient === Application.BootPhase.UP &&
    state.router.state === Application.BootPhase.UP
  ) {
    return {
      ...state,
      state: Application.BootPhase.UP,
    };
  }

  return state;
}
