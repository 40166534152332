import { ReactElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RefundRow } from 'src/components/pages/Users/Refunds/RefundRow';
import { RefundsFilterForm } from 'src/components/pages/Users/Refunds/RefundsFilterForm';
import { useUrlParamsQuery } from 'src/components/RecordsTable/hooks';
import RecordsTable, { Query } from 'src/components/RecordsTable/RecordsTable';
import { InstallationsProvider } from 'src/contexts/InstallationsContext';
import { useRefunds } from 'src/services/hooks/useRefunds';
import * as Yup from 'yup';

export default function Refunds(): ReactElement {
  const { t } = useTranslation();

  const searchFiltersSchema = Yup.object().shape({
    eshopId: Yup.string().default(''),
    eshopInternals: Yup.string().default(''),
    billingCompany: Yup.string().default(''),
    eshopUrl: Yup.string().default(''),
    amountFrom: Yup.string().default(''),
    amountTo: Yup.string().default(''),
    state: Yup.string().default(''),
    currency: Yup.string().default(''),
    dateFrom: Yup.string().default(''),
    dateTo: Yup.string().default(''),
  });

  const buildInitialQuery = (params: Record<string, string>): Query => ({
    ...searchFiltersSchema.getDefaultFromShape(),
    sort: '-createdAt',
    ...params,
  });

  const [query, params, setQuery] = useUrlParamsQuery(buildInitialQuery);
  const { listing, onQuery } = useRefunds();

  const refetchData = useCallback(() => {
    onQuery(params);
  }, [onQuery, params]);

  useEffect(refetchData, [refetchData]);

  const headers = [
    { label: t('users:filter:common.projectId') },
    { label: t('users:table.common.name'), orderKey: 'eshopUrl' },
    { label: t('users:filter.common.billingCompany') },
    { label: t('users:table.common.eshopInternals') },
    { label: t('refunds:table.detailLinkHeader') },
    { label: t('refunds:table.date'), orderKey: 'createdAt' },
    { label: t('refunds:table.amount'), orderKey: 'amount' },
    { label: t('form:currency') },
    { label: t('users:table.common.state') },
    { label: t('users:table.common.action') },
  ];

  return (
    <InstallationsProvider value={{ refetchData }}>
      <RecordsTable<Refund>
        queryResult={listing}
        renderRow={(record: Refund): JSX.Element => (
          <RefundRow refund={record} key={record.id} />
        )}
        filtersForm={<RefundsFilterForm />}
        headers={headers}
        query={query}
        filtersSchema={searchFiltersSchema}
        onQueryChange={setQuery}
      />
    </InstallationsProvider>
  );
}
