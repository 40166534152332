import { formatDate } from 'src/components/shared/utils/date';

export const removeEmptyObjectValues = (
  obj: Record<string, any>,
): Record<string, any> =>
  Object.entries(obj).reduce(
    (a: Record<string, any>, [k, v]: [string, any]) =>
      v && v !== '-1' ? ((a[k] = v), a) : a,
    {},
  );

export const formatAllObjectDateValues = (
  obj: Record<string, any>,
): Record<string, any> =>
  Object.entries(obj).reduce(
    (p: Record<string, any>, [k, v]: [string, any]) => ({
      ...p,
      [k]: v instanceof Date ? formatDate(obj[k], 'yyyy-MM-dd') : obj[k],
    }),
    {},
  );

export const omitObjectKeys = <T>(
  obj: Record<string, any>,
  omittedKeys: T,
): Record<string, any> =>
  Array.isArray(omittedKeys)
    ? Object.entries(obj).reduce(
        (p: Record<string, any>, [k, v]: [string, any]) => ({
          ...p,
          ...(!omittedKeys.includes(k) && { [k]: v }),
        }),
        {},
      )
    : obj;

export const omitObjectKeysExceptSelected = <T>(
  obj: Record<string, any>,
  omittedKeys: T,
): Record<string, any> =>
  Array.isArray(omittedKeys)
    ? Object.entries(obj).reduce(
        (p: Record<string, any>, [k, v]: [string, any]) => {
          if (omittedKeys.includes(k)) {
            p[k] = v;
          }
          return p;
        },
        {},
      )
    : obj;

export const getAllParamsFromUrl = (
  urlSearch: string,
): Record<string, string> =>
  urlSearch
    .slice(1)
    .split('&')
    .reduce((p: Record<string, string>, v: string) => {
      const valueSplit = v.split('=');
      if (!valueSplit[0]) {
        return p;
      }
      p[valueSplit[0]] = decodeURIComponent(valueSplit[1]);
      return p;
    }, {});
