import React, { useRef } from 'react';
import footerBgImg from 'src/public/assets/footer_bg.png';

interface TooltipProps {
  innerTooltipContent: string | JSX.Element;
}

export const Tooltip: React.FC<TooltipProps> = ({
  innerTooltipContent,
}: TooltipProps) => {
  const refDiv = useRef<HTMLDivElement>(null);
  const refSpan = useRef<HTMLSpanElement>(null);

  const onMouseOverTooltip = (): void => {
    if (refDiv.current && refSpan.current) {
      refDiv.current.style.display = 'block';
      const top = refSpan.current.offsetTop;
      const widthDiv = refDiv.current.clientWidth;
      const widthSpan = refSpan.current.clientWidth;
      const height = refDiv.current.clientHeight;
      refDiv.current.style.top = `${top - height}px`;
      refDiv.current.style.left = `-${widthDiv * 0.5 - widthSpan * 0.75}px`;
    }
  };

  const onMouseOutTooltip = (): void => {
    if (refDiv.current && refSpan.current) {
      refDiv.current.style.display = 'none';
    }
  };

  return (
    <div
      style={{ position: 'relative', display: 'inline-block', paddingLeft: 5 }}
    >
      <div
        onMouseOver={onMouseOverTooltip}
        onMouseOut={onMouseOutTooltip}
        ref={refDiv}
        className="tooltip center"
        style={{ display: 'none', position: 'absolute' }}
      >
        <div className="tooltip-header" />
        <div className="tooltip-content-wrapper">
          <div className="tooltip-content">{innerTooltipContent}</div>
        </div>
        <div
          className="tooltip-footer"
          style={{ backgroundImage: `url('${footerBgImg}')` }}
        />
      </div>
      <span
        ref={refSpan}
        onMouseOver={onMouseOverTooltip}
        onMouseOut={onMouseOutTooltip}
        className="show-tooltip tooltip-icon"
      />
    </div>
  );
};
