import React, { ReactElement, useCallback, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { spt } from '@shoptet-pay/fe-toolkit';
import { parseDate } from 'src/components/shared/utils/date';
import { connectToState } from 'src/components/appState';
import { cs, hu, pl, sk } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';

registerLocale('cs', cs);
registerLocale('hu', hu);
registerLocale('pl', pl);
registerLocale('sk', sk);

interface Props {
  name: string;
  label?: string;
}

interface StateProps {
  locale: string;
}

export default connectToState<StateProps, Props>(
  state => ({
    locale: state.settings.locale,
  }),
  DatePickerInput,
);

function DatePickerInput({
  name,
  locale,
  label = '',
}: Props & StateProps): ReactElement {
  const [labelFor] = useState(String(Math.random()));
  const { setFieldValue, submitForm } = useFormikContext<unknown>();
  const [field] = useField(name);
  const onChange = (value: Date | undefined): void => {
    setFieldValue(name, value ?? '');
  };

  const onSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  return (
    <div>
      <label htmlFor={labelFor}>{label}</label>
      <spt.form.DateSelect
        value={parseDate(field.value)}
        onChange={onChange}
        onSubmit={onSubmit}
        locale={locale}
      />
    </div>
  );
}
