import { ReactElement } from 'react';
import IconBase, { Options } from './IconBase';

export default function FillAlert(options: Options): ReactElement {
  return (
    <IconBase options={options} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 0,11.999999 C 0,5.37258 5.372581,0 12.000001,0 c 6.6242,0.0077148 11.9923,5.37578 12,11.999999 0,6.6274 -5.3726,12 -12,12 -6.62742,0 -12.000001,-5.3726 -12.000001,-12 z m 12.000001,6 c -0.8284,0 -1.5,-0.1869 -1.5,-0.4176 v -2.1648 c 0,-0.2307 0.6716,-0.4176 1.5,-0.4176 0.8284,0 1.5,0.1869 1.5,0.4176 v 2.1648 c 2e-4,0.1108 -0.1577,0.217 -0.4391,0.2954 -0.2813,0.0783 -0.663,0.1223 -1.0609,0.1222 z m 0,-4.5 c -0.8284,0 -1.5,-0.4674 -1.5,-1.0439 V 7.0439 c 0,-0.57653 0.6716,-1.0439 1.5,-1.0439 0.8284,0 1.5,0.46737 1.5,1.0439 v 5.412199 c 2e-4,0.2769 -0.1577,0.5425 -0.4391,0.7383 -0.2813,0.1958 -0.663,0.3058 -1.0609,0.3056 z"
      />
    </IconBase>
  );
}
