import React from 'react';
import { NotApprovedRow } from 'src/components/pages/Users/NotApproved/NotApprovedRow';
import StateTable from 'src/components/pages/Users/StateTable';
import { ServiceState } from 'src/consts/ServiceState';

export const NotApproved: React.FC = () => (
  <StateTable
    states={[ServiceState.SHOPTET_APPROVAL, ServiceState.SHOPTET_ON_HOLD]}
    content={(installation: Installation): JSX.Element => (
      <NotApprovedRow installation={installation} key={installation.id} />
    )}
  />
);
