import { ReactElement } from 'react';
import IndexTabs from 'src/components/pages/Users/Index/IndexTabs';
import { Processing } from 'src/components/pages/Users/Processing/Processing';

ProcessingRoute.route = 'processing';

export default function ProcessingRoute(): ReactElement {
  return (
    <>
      <IndexTabs />
      <Processing />
    </>
  );
}
