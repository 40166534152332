import { MouseEvent, PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useFormikContext } from 'formik';
import {
  getUrlParams,
  storageUrlKeyGenerator,
} from 'src/shared/localStorage/urlParams';
import {
  getAllParamsFromUrl,
  omitObjectKeys,
  omitObjectKeysExceptSelected,
} from 'src/components/shared/utils/functionHelpers';
import logger from 'src/services/logger';

interface FilterFormProps<T> {
  isSubmitting: boolean;
  visible: boolean;
  setVisible(visible: boolean): void;
  query: QueryFilter;
  setQuery(query: QueryFilter): void;
  defaultValues: T;
}

export const FilterForm = <T,>({
  isSubmitting,
  visible,
  setVisible,
  query,
  setQuery,
  children,
  defaultValues,
}: PropsWithChildren<FilterFormProps<T>>): JSX.Element => {
  const { t } = useTranslation('filter');
  const { submitForm, resetForm, setFieldValue } = useFormikContext();

  useEffect(() => {
    let params = getAllParamsFromUrl(window.location.search);
    if (!Object.keys(params).length) {
      params = getUrlParams(storageUrlKeyGenerator());
    }
    const filterKeys = omitObjectKeys<(keyof OmitQuery)[]>(params, [
      'state',
      'sort',
      'limit',
      'offset',
    ]);
    Object.keys(filterKeys).forEach((key: string) => {
      setFieldValue(key, filterKeys[key]);
    });
    const queryKeys = omitObjectKeysExceptSelected<(keyof OmitQuery)[]>(
      params,
      ['state', 'sort', 'limit', 'offset'],
    );
    setQuery({
      ...query,
      ...queryKeys,
      ...filterKeys,
    });
    if (Object.keys(filterKeys).length) {
      setVisible(true);
    }
    // call useEffect only on init component not during changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitInner = (e: MouseEvent): void => {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }
    setQuery(query);
    submitForm().catch((e: unknown) => logger.error(e));
  };

  const handleReset = (e: MouseEvent): void => {
    e.preventDefault();
    if (isSubmitting) {
      return;
    }
    resetForm({ values: defaultValues });
    submitForm().catch((e: unknown) => logger.error(e));
  };

  return (
    <Form id="users-filter-form" className="dont-display-exit-confirm">
      <fieldset className={`custom-overflow ${visible ? 'visible' : ''}`}>
        <div className={`custom-overflow ${visible ? 'visible' : ''}`}>
          <div id="filter">
            <div className="filter-grid">{children}</div>
          </div>
          <div className="filter-buttons">
            <button
              title={t('filter')}
              className="btn btn-md btn-action submit-js"
              onClick={handleSubmitInner}
            >
              {t('filter')}
            </button>
            &ensp;
            <button
              title={t('clear')}
              className="btn btn-md btn-default"
              onClick={handleReset}
            >
              {t('clear')}
            </button>
          </div>
        </div>
      </fieldset>
    </Form>
  );
};
