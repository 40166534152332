import { ReactElement } from 'react';
import { connectToState } from 'src/components/appState';
import RoutesTabs from 'src/components/routes/common/RoutesTabs';
import { Pages } from 'src/consts/Pages';
import { Application } from 'src/models';

interface StateProps {
  route?: Application.Router.RouteState;
}

export default connectToState<StateProps>(
  state => ({
    route: state.route,
  }),
  IndexTabs,
);

function IndexTabs({ route }: StateProps): ReactElement {
  return (
    <RoutesTabs
      page={Pages.overview}
      routes={[
        'unapproved',
        'processing',
        'active',
        'suspended',
        'banned',
        'refunds',
        'pos',
      ]}
      currentRoute={route?.route ?? 'index'}
    />
  );
}
