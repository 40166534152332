import { Query } from './RecordsTable';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  formatAllObjectDateValues,
  omitObjectKeys,
  removeEmptyObjectValues,
} from 'src/components/shared/utils/functionHelpers';
import { Application } from 'src/models';
import { useStaticMemo } from 'src/utils/customReactHooks';
import { useAppState } from 'src/components/appState';

type Params = Record<string, any>;

export function useUrlParamsQuery(
  parseQuery: (params: Params) => Query,
): [Query, Record<string, string>, (query: Query) => void] {
  const [state, update] = useAppState();
  const route = Application.Router.assureRoutePresence(state.route);

  const initialQuery = useStaticMemo(() =>
    parseQuery(state.route?.params ?? {}),
  );

  const [query, setQuery] = useState(initialQuery);

  const onSetQuery = useCallback((query: Query): void => {
    setQuery(query);
  }, []);

  const params = useMemo(() => queryToParams(query), [query]);

  useEffect(() => {
    update(state => ({
      ...state,
      route: {
        page: route.page,
        route: route.route,
        params,
      },
    }));
  }, [route.page, route.route, params, update]);

  return [query, params, onSetQuery];
}

function queryToParams(query: Query): Params {
  return omitObjectKeys(
    formatAllObjectDateValues(removeEmptyObjectValues(query)),
    ['month'],
  );
}
