import useAxios from 'axios-hooks';
import { useCallback, useState } from 'react';
import { QueryParams } from 'src/services/api/types';

export function useStatistics(): {
  statistics: TransactionStatistics | undefined;
  loading: boolean;
  query: StatisticsQuery;
  setQueryAndRefetchData(query: StatisticsQuery): void;
} {
  const [query, setQuery] = useState<StatisticsQuery>({
    currency: 'CZK',
  });

  const [{ data: getData, loading: getLoading }, fetchData] =
    useAxios<TransactionStatistics>(
      {
        url: 'v1/statistics/transaction',
        method: 'GET',
        params: query,
      },
      { manual: true },
    );

  const setQueryAndRefetchData = useCallback(
    (params: QueryParams): void => {
      setQuery(params);
      fetchData({ params }).catch(() => null);
    },
    [fetchData],
  );

  return {
    statistics: getData,
    loading: getLoading,
    query,
    setQueryAndRefetchData,
  };
}
