import React from 'react';

const Loading: React.FC = (): JSX.Element => (
  <div
    className="page-loader"
    data-testid="loading"
    style={{ display: 'block' }}
  >
    <svg height="100" width="100" className="registration-circular">
      <circle
        cx="50"
        cy="50"
        r="40"
        fill="none"
        strokeWidth="2"
        strokeMiterlimit="20"
        className="registration-path"
      />
    </svg>
  </div>
);

export default Loading;
