import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { Field, useFormikContext } from 'formik';

interface InputProps {
  name: string;
  type?: string;
  label?: string;
}
const Input: React.FC<InputProps> = ({
  name,
  type = 'text',
  label = '',
}: InputProps) => {
  const [labelFor] = useState(String(Math.random()));
  const { setFieldValue, submitForm } = useFormikContext<unknown>();
  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setFieldValue(name, value || '');
  };

  const onKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      submitForm();
    }
  };

  return (
    <div>
      <label htmlFor={labelFor}>{label}</label>
      <Field
        name={name}
        type={type}
        id={labelFor}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default Input;
