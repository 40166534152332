import { AxiosResponse } from 'axios';
import ApiClient from './ApiClient';
import { useApi } from 'src/components/Application/ApiProvider';

export { ApiClient, useApi };

export enum LoadingState {
  loading = 'loading',
  failed = 'failed',
  done = 'done',
}

export type RequestResult<D> =
  | { state: LoadingState.failed; error: any }
  | { state: LoadingState.done; response: AxiosResponse<D>; data: D };
