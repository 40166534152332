import { ReactElement, useMemo } from 'react';
import classNames from 'classnames';
import { Pages } from 'src/consts/Pages';
import { connectToState } from 'src/components/appState';
import { payCrmPageRouteTranslations } from 'src/i18n';
import LinkToRoute from 'src/components/Application/Router/LinkToRoute';

interface StateProps {
  locale: string;
}

interface ExternalProps {
  className?: string;
  page: Pages;
  routes: string[];
  currentRoute: string;
}

export default connectToState<StateProps, ExternalProps>(
  state => ({
    locale: state.settings.locale,
  }),
  RoutesTabs,
);

function RoutesTabs({
  locale,
  className,
  page,
  routes,
  currentRoute,
}: StateProps & ExternalProps): ReactElement {
  return (
    <div className={classNames('stp-routes-tabs tabs', className)}>
      <ul className="dropdown-ready">
        {routes.map((route, index) => (
          <TabItem
            key={index}
            locale={locale}
            page={page}
            route={route}
            isActive={currentRoute === route}
          />
        ))}
        <li
          className="ui-state-default dropdown-handler"
          style={{ display: 'none' }}
        >
          <a>...</a>
          <ul
            className="dropdown-list list-reset"
            style={{ display: 'none', overflow: 'auto' }}
          />
        </li>
      </ul>
    </div>
  );
}

function TabItem({
  locale,
  page,
  route,
  isActive,
}: {
  locale: string;
  page: Pages;
  route: string;
  isActive: boolean;
}): ReactElement {
  const caption = useMemo(() => {
    const routeTranslations = payCrmPageRouteTranslations(locale, page, route);
    return (routeTranslations?.tabCaption ??
      routeTranslations?.title ??
      `${page}#${route}`) as string;
  }, [locale, page, route]);

  return (
    <li className={classNames({ active: isActive })}>
      {!isActive ? (
        <LinkToRoute page={page} route={route}>
          <span>{caption}</span>
        </LinkToRoute>
      ) : (
        <a>{caption}</a>
      )}
    </li>
  );
}
