import { createContext } from 'react';

interface InstallationsContextType {
  refetchData(): void;
}
export const InstallationsContext = createContext<InstallationsContextType>({
  refetchData() {
    // empty default
  },
});
export const InstallationsProvider = InstallationsContext.Provider;
