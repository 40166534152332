import MockAdapter from 'axios-mock-adapter';

export default function mock(mockApi: MockAdapter): void {
  mockApi.onPost('v1/credit-check-url').reply(() => [
    200,
    {
      url: 'https://www.creditcheck.cz/CCLfree.aspx?ic=63493551',
    },
  ]);
}
