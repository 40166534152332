import { createElement, ReactElement } from 'react';
import routes from 'src/components/routes/Statistics/routes';
import { Pages } from 'src/consts/Pages';
import { Application } from 'src/models';

StatisticsPage.page = Pages.statistics;
StatisticsPage.routes = routes;

export default function StatisticsPage({
  routeComponent,
}: Application.Router.PageComponentProps): ReactElement {
  return <>{createElement(routeComponent)}</>;
}
