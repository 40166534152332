import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectOptionsType } from './Select';

export default function ServiceSelect(): ReactElement {
  const { t } = useTranslation();

  const internalOptions: SelectOptionsType[] = useMemo(() => {
    const options = [
      { id: '', value: '' },
      { id: 'card', value: t('users:table.common.services.card') },
      { id: 'pb', value: t('users:table.common.services.pb') },
      { id: 'pos', value: t('users:table.common.services.pos') },
    ];
    return options;
  }, [t]);

  return (
    <Select
      hasDefaultDisabledOption
      disableEmpty={false}
      options={internalOptions}
      name="service"
      label={t('users:filter.common.service')}
    />
  );
}
