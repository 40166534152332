import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'src/components/forms/Input';
import EshopInternalsSelect from 'src/components/forms/EshopInternalsSelect';
import { CurrencySelect } from 'src/components/forms/CurrencySelect';
import { AdyenRefundState } from 'src/consts/RefundState';
import { Select, SelectOptionsType } from 'src/components/forms/Select';
import DatePickerInput from 'src/components/forms/DatePickerInput';

export const RefundsFilterForm: React.FC = (): JSX.Element => {
  const { t } = useTranslation();

  const stateOptions: SelectOptionsType[] = useMemo(
    () => [
      { id: '', value: '' },
      ...Object.values(AdyenRefundState).map(item => ({
        id: item,
        value: t(`refunds:state.${item}`),
      })),
    ],
    [t],
  );

  return (
    <>
      <Input name="eshopId" label={t('users:filter.common.projectId')} />
      <Input name="eshopUrl" label={t('users:filter.common.project')} />
      <Input
        name="billingCompany"
        label={t('users:filter.common.billingCompany')}
      />
      <EshopInternalsSelect />
      <DatePickerInput name="dateFrom" label={t('refunds:filter.dateFrom')} />
      <DatePickerInput name="dateTo" label={t('refunds:filter.dateTo')} />
      <Input name="amountFrom" label={t('refunds:filter.amountFrom')} />
      <Input name="amountTo" label={t('refunds:filter.amountTo')} />
      <CurrencySelect name="currency" prependEmpty hasDefaultDisabledOption />
      <Select
        options={stateOptions}
        disableEmpty={false}
        name="state"
        label={t('users:table.common.state')}
        hasDefaultDisabledOption
      />
    </>
  );
};
