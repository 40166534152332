import { ReactElement } from 'react';
import IconBase, { Options } from './IconBase';

export default function check(options: Options): ReactElement {
  return (
    <IconBase options={options} viewBox="0 0 24 24">
      <path
        d="m 21.873384,7.06819 -3.4123,-3.437404 C 18.377984,3.5470564 18.265184,3.5 18.147484,3.5 c -0.1176,0 -0.2304,0.047056 -0.3135,0.130786 L 9.2689848,12.24793 c -0.08308,0.08373 -0.19589,0.13079 -0.31354,0.13079 -0.11764,0 -0.23045,-0.04706 -0.31353,-0.13079 l -2.47284,-2.49296 c -0.08308,-0.08373 -0.19589,-0.13078 -0.31353,-0.13078 -0.11765,0 -0.23046,0.04705 -0.31354,0.13078 l -3.4123501,3.43741 c -0.172873,0.17383 -0.172873,0.45562 0,0.62942 l 6.5122601,6.5474 c 0.08308,0.0837 0.19589,0.1308 0.31353,0.1308 0.11765,0 0.23046,-0.0471 0.31354,-0.1308 L 21.873384,7.69143 c 0.1688,-0.1731 0.1688,-0.45014 0,-0.62324 z"
        fill="#7d9e1e"
      />
    </IconBase>
  );
}
