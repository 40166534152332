import React from 'react';

interface EshopNameColumnProps {
  installation: Installation;
}

export const EshopNameColumn: React.FC<EshopNameColumnProps> = ({
  installation,
}: EshopNameColumnProps) => {
  const formattedEshopName = (): string => {
    const splitName = installation.eshopUrl.split('//');

    if (splitName[1]) {
      return splitName[1].replace(/\/$/g, '');
    }

    return installation.eshopUrl;
  };

  return (
    <td>
      {formattedEshopName()}
      &nbsp;|&nbsp;
      <a href={installation.eshopUrl} target="_blank" rel="noreferrer">
        web
      </a>
      &nbsp;|&nbsp;
      <a
        href={`/admin/project-detail/?id=${installation.eshopId}`}
        target="_blank"
        rel="noreferrer"
      >
        crm
      </a>
    </td>
  );
};
