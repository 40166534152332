import {
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { useAppState } from 'src/components/appState';
import { Pages } from 'src/consts/Pages';
import { Application } from 'src/models';

export default function LinkToRoute({
  page,
  route,
  className,
  children,
}: {
  page?: Pages;
  route: string;
  className?: string;
  children: ReactNode;
}): ReactElement {
  const [state] = useAppState();
  const router = Application.Router.useRouter();
  const newPage = page ?? state.route?.page;

  if (!newPage) {
    throw new Error('STP [ERR] undetermined page for LinkToRoute');
  }

  const href = useMemo(
    () =>
      router.config.getFullPathForRoute(
        { page: newPage, route },
        state.settings.locale,
      ),
    [newPage, route, router.config, state.settings.locale],
  );

  const onClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>): void => {
      event.preventDefault();
      router.goToRoute(newPage, route);
    },
    [newPage, route, router],
  );

  return (
    <a href={href} className={className} onClick={onClick}>
      {children}
    </a>
  );
}
