import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EshopNameColumn } from 'src/components/pages/Users/common/EshopNameColumn';
import { SwitchRefundState } from 'src/components/pages/Users/common/SwitchRefundState';
import { useCurrencyFormat } from 'src/services/hooks/useCurrencyFormat';

interface RefundRowProps {
  refund: Refund;
}

export const RefundRow: React.FC<RefundRowProps> = ({
  refund,
}: RefundRowProps) => {
  const { t } = useTranslation('refunds');
  const { getDisplayBalance } = useCurrencyFormat();

  const detailLink = useMemo(
    () =>
      new URL(
        `https://${refund.payment.installation.eshopId}.myshoptet.com/superadmin/shoptet-pay-payments-listing/#/transactions/${refund.payment.transaction.id}`,
      ).toString(),
    [refund.payment.installation.eshopId, refund.payment.transaction.id],
  );

  return (
    <tr>
      <td>{refund.payment.installation.eshopId}</td>
      <EshopNameColumn installation={refund.payment.installation} />
      <td>{refund.payment.installation.billingCompany}</td>
      <td>{refund.payment.installation.eshopInternals}</td>
      <td>
        <a href={detailLink} target="_blank" rel="noreferrer">
          {t('table.detailLink')}
        </a>
      </td>
      <td>
        {t('table.createdAt', {
          date: new Date(refund.createdAt),
        })}
      </td>
      <td className="tar">
        {getDisplayBalance(Number(refund.amount), refund.currency)}
      </td>
      <td>{refund.currency}</td>
      <td>{t(`state.${refund.state}`)}</td>
      <td>
        <SwitchRefundState options={refund.allowedStates} refund={refund} />
      </td>
    </tr>
  );
};
