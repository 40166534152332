import {
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import Loading from 'src/components/common/Loading';
import FilterForm from 'src/components/RecordsTable/FilterForm';
import { Pagination } from 'src/components/RecordsTable/Pagination';
import { SortingTh } from 'src/components/RecordsTable/SortingTh';
import SystemMessage from 'src/components/SystemMessage/SystemMessage';
import { defaultPageLimit } from 'src/services/api/constants/pagination';
import { QueryResult } from 'src/services/api/types';

interface HeaderItem {
  label: string;
  orderKey?: string;
}

export type Query = Record<string, undefined | string | Date>;

interface QueryResultData<R> {
  count: number;
  records: R[];
}

export default function RecordsTable<R>({
  queryResult,
  renderRow,
  headers,
  query,
  filtersForm,
  filtersSchema,
  onQueryChange,
}: {
  queryResult: QueryResult<QueryResultData<R>>;
  renderRow(record: R, index?: number): ReactElement;
  headers: HeaderItem[];
  query: Query;
  filtersForm?: ReactNode;
  filtersSchema?: any;
  onQueryChange(query: Query): void;
}): ReactElement {
  const { t } = useTranslation('components');

  const initialFiltersVisible = useMemo(() => {
    if (!filtersSchema) {
      return false;
    }
    for (const field of Object.keys(filtersSchema.fields)) {
      if (query[field]) {
        return true;
      }
    }
    return false;
  }, [filtersSchema, query]);

  const [filtersVisible, setFiltersVisible] = useState(initialFiltersVisible);
  const onToggleFilters = useCallback(
    (e: MouseEvent): void => {
      e.preventDefault();
      setFiltersVisible(!filtersVisible);
    },
    [filtersVisible, setFiltersVisible],
  );

  const onSortChange = useCallback(
    (sort: string) => onQueryChange({ ...query, sort }),
    [query, onQueryChange],
  );

  const paginationOffset = useMemo(() => {
    const offset = query.offset ? Number(query.offset) : NaN;
    return isNaN(offset) ? 0 : offset;
  }, [query]);
  const paginationLimit = useMemo(() => {
    const limit = query.limit ? Number(query.limit) : NaN;
    return isNaN(limit) ? defaultPageLimit : limit;
  }, [query]);
  const onOffsetChange = useCallback(
    (offset: number) => {
      const newQuery = { ...query };
      if (offset === 0) {
        delete newQuery.offset;
      } else {
        newQuery.offset = String(offset);
      }
      onQueryChange(newQuery);
    },
    [query, onQueryChange],
  );
  const onLimitChange = useCallback(
    (limit: number, offset: number) => {
      const newQuery = { ...query };

      if (limit === 0) {
        delete newQuery.limit;
      } else {
        newQuery.limit = String(limit);
      }

      if (offset === 0) {
        delete newQuery.offset;
      } else {
        newQuery.offset = String(offset);
      }

      onQueryChange(newQuery);
    },
    [query, onQueryChange],
  );
  const onFormikSubmit = useCallback(
    (values: Query) => {
      const params = { ...query, ...values };
      if (params.offset !== undefined) {
        delete params.offset;
      }
      onQueryChange(params);
    },
    [query, onQueryChange],
  );

  // this keeps previous value as current to prevent flicker between loads
  const [cachedResult, setCachedResult] = useState(queryResult);
  useEffect(() => {
    if (!queryResult.idle && !queryResult.processing) {
      setCachedResult(queryResult);
    }
  }, [queryResult]);

  const recordsTotal =
    !cachedResult.idle && !cachedResult.processing && !cachedResult.failed
      ? cachedResult.data.count
      : 0;

  return (
    <>
      {(queryResult.idle || queryResult.processing) && <Loading />}
      <div className="std-filter">
        {filtersForm && filtersSchema && (
          <>
            <span id="filter-toggle" className="toggle-button">
              <a onClick={onToggleFilters}>{t('RecordsTable.toggleFilter')}</a>
            </span>
            <Formik
              validationSchema={filtersSchema}
              initialValues={query}
              onSubmit={onFormikSubmit}
            >
              {(): ReactNode => (
                <FilterForm visible={filtersVisible}>{filtersForm}</FilterForm>
              )}
            </Formik>
          </>
        )}
      </div>
      <div className="table-holder">
        <table className="std-table std-table-listing">
          <thead>
            <HeaderRow
              headers={headers}
              currentSort={(query.sort ?? '') as string}
              onSortChange={onSortChange}
            />
          </thead>
          <tbody>
            <Content
              queryResult={cachedResult}
              renderRow={renderRow}
              columnsCount={headers.length}
            />
          </tbody>
        </table>
      </div>
      <Pagination
        count={recordsTotal}
        offset={paginationOffset}
        limit={paginationLimit}
        onOffsetChange={onOffsetChange}
        onLimitChange={onLimitChange}
      />
    </>
  );
}

function HeaderRow({
  headers,
  currentSort,
  onSortChange,
}: {
  headers: HeaderItem[];
  currentSort: string;
  onSortChange(sort: string): void;
}): ReactElement {
  return (
    <tr>
      {headers.map(
        ({ label, orderKey }: HeaderItem, index: number): ReactElement => {
          if (!orderKey) {
            return <th key={index}>{label}</th>;
          }
          return (
            <SortingTh
              key={orderKey}
              sort={currentSort}
              name={label}
              orderKey={orderKey}
              setQuery={onSortChange}
            />
          );
        },
      )}
    </tr>
  );
}

function Content<R>({
  queryResult,
  renderRow,
  columnsCount,
}: {
  queryResult: QueryResult<QueryResultData<R>>;
  renderRow(record: R, index?: number): ReactElement;
  columnsCount: number;
}): ReactElement {
  const { t } = useTranslation('components');

  if (queryResult.idle || queryResult.processing) {
    return <></>;
  }

  if (queryResult.failed) {
    return (
      <tr>
        <td colSpan={columnsCount}>
          <SystemMessage level="error">
            <p>{t('RecordsTable.failed')}</p>
          </SystemMessage>
        </td>
      </tr>
    );
  }

  if (queryResult.data.count === 0) {
    return (
      <tr>
        <td colSpan={columnsCount}>
          <p>{t('RecordsTable.noRecords')}</p>
        </td>
      </tr>
    );
  }

  return <>{queryResult.data.records.map(renderRow)}</>;
}
