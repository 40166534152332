import axiosModule, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import MockAdapter from 'axios-mock-adapter';
import configuration from 'src/configuration';
import { RequestResult, LoadingState } from 'src/services/api';
import logger from 'src/services/logger';

interface AuthTokens {
  access_token: string;
  refresh_token: string;
}

export default class ApiClient {
  axios: AxiosInstance = axiosModule.create({
    baseURL: configuration.apiBaseURL,
  });

  connected: boolean = false;

  async request<D = any>(
    fn: <P>(axios: AxiosInstance) => Promise<AxiosResponse<D, P>>,
  ): Promise<RequestResult<D>> {
    if (!this.connected) {
      return {
        state: LoadingState.failed,
        error: new Error('STP:API: not connected'),
      };
    }

    try {
      const response = await fn(this.axios);
      return { state: LoadingState.done, response, data: response.data };
    } catch (error: any) {
      return { state: LoadingState.failed, error };
    }
  }

  static async connect(
    client: ApiClient,
    {
      authCode,
      onReconnectionFailed,
      onGlobalFailIntercepted,
    }: {
      authCode: string;
      onReconnectionFailed(): void;
      onGlobalFailIntercepted(response: AxiosResponse): void;
    },
  ): Promise<void> {
    if (client.connected) {
      throw new Error('STP:API: already connected');
    }

    const response = await client.axios.post('v1/oauth/token', {
      grant_type: 'authorization_code',
      code: authCode,
    });

    ApiClient.setAxiosInterceptors(client, {
      initialTokens: response.data,
      onReconnectionFailed,
      onGlobalFailIntercepted,
    });

    client.connected = true;
  }

  static setAxiosInterceptors(
    client: ApiClient,
    {
      initialTokens,
      onReconnectionFailed,
      onGlobalFailIntercepted,
    }: {
      initialTokens: AuthTokens;
      onReconnectionFailed(): void;
      onGlobalFailIntercepted(response: AxiosResponse): void;
    },
  ): void {
    const axios = client.axios;
    let tokens = initialTokens;

    if (configuration.devMode?.logApiRequests) {
      axios.interceptors.request.use(
        async (config: AxiosRequestConfig) => {
          logger.log('api', config.method, config.url, { config });
          return config;
        },
        async (error: Error) => {
          logger.error(error);
          return Promise.reject(error);
        },
      );
    }

    // add access token to requests
    axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${tokens.access_token}`,
      };
      return config;
    });

    // automatically refresh tokens on denied access
    axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: any) => {
        const config = error.config;
        if (
          error.response?.status === 401 &&
          !axiosModule.isCancel(error) &&
          !config._retry
        ) {
          config._retry = true;
          try {
            const { data } = await axios.post('v1/oauth/token', {
              grant_type: 'refresh_token',
              refresh_token: tokens.refresh_token,
            });
            tokens = data;
            return await axios(config);
          } catch (_) {
            client.connected = false;
            onReconnectionFailed();
          }
        }
        return Promise.reject(error);
      },
    );

    // intercepts 500 fails when opted-in via config `_GlobalInterceptFail: true`
    axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: any) => {
        if (
          !axiosModule.isCancel(error) &&
          error.response.status >= 500 &&
          error.config._GlobalInterceptFail
        ) {
          onGlobalFailIntercepted(error.response);
        }
        return Promise.reject(error);
      },
    );
  }
}

export class ApiClientMock {
  adapter: MockAdapter;

  client: ApiClient;

  constructor(client: ApiClient = new ApiClient()) {
    this.client = client;
    this.adapter = new MockAdapter(client.axios);
  }

  reset(): void {
    this.adapter.reset();
  }

  mockGet(
    url: string,
    reply: (config: AxiosRequestConfig) => [number, Record<string, any>?],
  ): void {
    this.adapter.onGet(url).reply(reply);
  }

  mockPost(
    url: string,
    reply: (config: AxiosRequestConfig) => [number, Record<string, any>?],
  ): void {
    this.adapter.onPost(url).reply(reply);
  }

  mock(mocks?: ((mockApi: MockAdapter) => void)[]): void {
    mocks?.forEach(mock => mock(this.adapter));
  }

  mockAccessToken(): void {
    this.mockPost('v1/oauth/token', () => [
      200,
      {
        access_token: 'token',
        refresh_token: 'token',
      },
    ]);
  }
}
