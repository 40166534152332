import { MouseEvent, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { FilterForm } from 'src/components/common/Filter/FilterForm';
import { Table } from 'src/components/common/Filter/Table';
import { NoContent } from 'src/components/forms/NoContent';
import { useSaveFilterParams } from 'src/services/hooks/urlModifications/useSaveFilterParams';

interface FilterProps<T, P, N> {
  values: T;
  schema: any;
  data: P[];
  filterForm: JSX.Element;
  content(renderContentRow: P): JSX.Element | JSX.Element[];
  headers: HeaderFilter[];
  query: QueryFilter;
  loading?: boolean;
  settings?: JSX.Element;
  setQuery(query: QueryFilter): void;
  submitFilter(values: T, { setSubmitting }: FormikHelpers<T>): void;
  never?: N;
  defaultValues: T;
}

export const Filter = <
  T extends FormikValues,
  P,
  N extends FilterProps<T, P, N>,
>({
  values,
  schema,
  data,
  filterForm,
  content,
  headers,
  query,
  loading,
  settings,
  setQuery,
  submitFilter,
  defaultValues,
}: FilterProps<T, P, N>): JSX.Element => {
  const { t } = useTranslation('filter');
  const { submitFilterSaveParams } = useSaveFilterParams<T>(
    values,
    submitFilter,
    query,
    setQuery,
  );
  const [formVisible, setFormVisible] = useState(false);

  const openFilter = (e: MouseEvent): void => {
    e.preventDefault();
    setFormVisible(!formVisible);
  };

  return (
    <>
      <div className="std-filter">
        <span id="filter-toggle" className="toggle-button">
          <a href="#" title="" onClick={openFilter}>
            {t('toggle')}
          </a>
        </span>
        <Formik<T>
          initialValues={values}
          onSubmit={submitFilterSaveParams}
          validationSchema={schema}
        >
          {({ isSubmitting }: any): ReactNode => (
            <FilterForm<T>
              query={query}
              setQuery={(): void => {
                // ignore
              }}
              setVisible={setFormVisible}
              visible={formVisible}
              isSubmitting={isSubmitting}
              defaultValues={defaultValues}
            >
              {filterForm}
            </FilterForm>
          )}
        </Formik>
      </div>
      {settings && settings}
      {data.length ? (
        <Table
          loading={loading}
          content={content}
          data={data}
          headers={headers}
        />
      ) : (
        <NoContent text={t('noContent')} />
      )}
    </>
  );
};
