import { spt } from '@shoptet-pay/fe-toolkit';
import { Application } from 'src/models';
import { ReactElement, ReactNode } from 'react';

const context = spt.appState.createContext<Application.State>({});

export const AppStateProvider: ({
  defaultState,
  onStateChange,
  children,
}: {
  defaultState: Application.State;
  onStateChange?(state: Application.State): void;
  children: ReactNode;
}) => ReactElement = context.Provider;

export const useAppState: () => [Application.State, Application.Update] =
  context.useAppState;

export function connectToState<SP, CP = Record<string, never>>(
  mapState: (state: Application.State, update: Application.Update) => SP,
  Component: (props: CP & SP) => ReactElement,
): (componentProps: CP) => ReactElement {
  return context.connectToState<SP, CP>(
    ([state, update]) => mapState(state, update),
    Component,
  );
}
