import React, { MouseEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface SortingThProps {
  sort: string;
  loading?: boolean;
  name: string;
  orderKey: string;
  setQuery(sort: string): void;
}

export const SortingTh: React.FC<SortingThProps> = ({
  sort,
  loading,
  name,
  orderKey,
  setQuery,
}: SortingThProps) => {
  const { t } = useTranslation('sorting');
  const linkRefAsc = useRef<HTMLAnchorElement | null>(null);
  const linkRefDesc = useRef<HTMLAnchorElement | null>(null);
  const handleSort = (e: MouseEvent, isSpecificSort?: boolean): void => {
    e.preventDefault();
    e.stopPropagation();
    if (loading) {
      return;
    }
    if (typeof isSpecificSort === 'boolean') {
      setQuery(isSpecificSort ? orderKey : `-${orderKey}`);
      return;
    }
    if (sort.endsWith(orderKey)) {
      setQuery(sort.startsWith('-') ? orderKey : `-${orderKey}`);
    } else {
      setQuery(orderKey);
    }
  };

  return (
    <th style={{ cursor: 'pointer' }} onClick={handleSort}>
      {name}
      <span className="sorting">
        <a
          ref={linkRefAsc}
          className={`asc ${sort === orderKey ? 'active' : ''}`}
          title={t('sortAsc')}
          href="#"
          onClick={(e: MouseEvent): void => handleSort(e, true)}
        >
          &nbsp;
        </a>
        <a
          ref={linkRefDesc}
          className={`desc ${sort === '-' + orderKey ? 'active' : ''}`}
          title={t('sortDesc')}
          href="#"
          onClick={(e: MouseEvent): void => handleSort(e, false)}
        >
          &nbsp;
        </a>
      </span>
    </th>
  );
};
