import { ReactElement } from 'react';
import { Pages } from 'src/consts/Pages';
import { Application } from 'src/models';
import { useLayoutEffectOnConnected } from 'src/utils/customReactHooks';

export default function SwitchRoute({
  page,
  route,
}: {
  page?: Pages;
  route: string;
}): ReactElement {
  const router = Application.Router.useRouter();
  useLayoutEffectOnConnected(() => {
    router.replaceRoute(page, route);
  });
  return <></>;
}
