import { ReactElement, useMemo } from 'react';
import NoMatch from 'src/components/App/NoMatch/NoMatch';
import { connectToState } from 'src/components/appState';
import { Application } from 'src/models';

interface StateProps {
  configuration?: Application.Router.Configuration;
  route?: Application.Router.RouteState;
}

export default connectToState<StateProps>(
  state => ({
    configuration: state.stack.router.configuration,
    route: state.route,
  }),
  Page,
);

function Page({ configuration, route }: StateProps): ReactElement {
  const page = useMemo(() => {
    if (configuration && route) {
      return configuration.getPageComponentAndProps(route);
    }
    return;
  }, [configuration, route]);

  return (
    <div className="content-page">
      <div id="spt--datepicker-portal" />
      {page ? <page.component {...page.props} /> : <NoMatch />}
    </div>
  );
}
