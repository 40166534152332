import { Application } from 'src/models';
import { useMemo } from 'react';
import Router from './Router';
import { useAppState } from 'src/components/appState';

export * from './types';
export * as helpers from './helpers';
export { default as PageRoutes } from './PageRoutes';
export { default as Configuration } from './Configuration';

export function getLocation(): Location {
  return window.location;
}

export function getHistory(): History {
  return window.history;
}

export function assureRoutePresence(
  route?: Application.Router.RouteState,
): Application.Router.RouteState {
  if (!route) {
    throw new Error('STP: [ERR] route not present');
  }
  return route;
}

export function useRouter(): Router {
  const [state, update] = useAppState();

  return useMemo(() => {
    const config = Application.Router.Configuration.assurePresence(
      state.stack.router.configuration,
    );
    return new Router(config, state.route, state.settings.locale, update);
  }, [
    state.stack.router.configuration,
    state.route,
    state.settings.locale,
    update,
  ]);
}
