import React, { ReactElement } from 'react';
import StateTable from 'src/components/pages/Users/StateTable';
import { SuspendRow } from 'src/components/pages/Users/Suspend/SuspendRow';
import { ServiceState } from 'src/consts/ServiceState';

export const Suspend: React.FC = () => (
  <StateTable
    states={[ServiceState.SUSPEND]}
    showState={false}
    content={(installation: Installation): ReactElement => (
      <SuspendRow installation={installation} key={installation.id} />
    )}
  />
);
