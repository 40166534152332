import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';

export interface SelectOptionsType {
  id: string | number;
  value: string;
}

interface SelectProps {
  name: string;
  label?: string;
  readOnly?: boolean;
  options: SelectOptionsType[];
  additionalClasses?: string;
  wrapClass?: string;
  preselectedValue?: string;
  hasDefaultDisabledOption?: boolean;
  disableEmpty?: boolean;
  handleSelectOnChange?(value: string): void;
}
export const Select: React.FC<SelectProps> = ({
  name,
  label,
  readOnly = false,
  options,
  additionalClasses = '',
  wrapClass = '',
  hasDefaultDisabledOption = false,
  preselectedValue = '',
  disableEmpty = true,
  handleSelectOnChange,
}: SelectProps) => {
  const [labelFor] = useState(String(Math.random()));
  const { values, setFieldValue } = useFormikContext<Record<string, string>>();
  const [value, setValue] = useState(options[0]?.id || '');

  function handleOnChange(e: React.ChangeEvent<HTMLSelectElement>): void {
    setFieldValue(name, e.target.value);
    setValue(e.target.value);
    handleSelectOnChange?.(e.target.value);
  }

  useEffect(() => {
    // set value to default on reset
    if (hasDefaultDisabledOption && values[name] !== value) {
      setValue(values[name]);
    } else if (preselectedValue) {
      setFieldValue(name, preselectedValue);
      setValue(preselectedValue);
    }
  }, [
    values,
    value,
    name,
    hasDefaultDisabledOption,
    preselectedValue,
    setFieldValue,
  ]);

  return (
    <div className={wrapClass}>
      {label && <label htmlFor={labelFor}>{label}</label>}
      <Field
        readOnly={readOnly}
        disabled={readOnly}
        name={name}
        as="select"
        value={value}
        onChange={handleOnChange}
        id={labelFor}
        className={additionalClasses}
      >
        {options.map((option: SelectOptionsType) => (
          <option
            key={option.id}
            value={option.id}
            disabled={!option.id && disableEmpty}
          >
            {option.value}
          </option>
        ))}
      </Field>
    </div>
  );
};
