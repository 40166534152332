import React, { MouseEventHandler } from 'react';

interface SystemMessageProps {
  level: 'error' | 'alert' | 'success';
  displayCloseButton?: boolean;
  closeConfirm?: boolean;
  closeConfirmText?: string;
  closeTitle?: string;
  onClose?: MouseEventHandler;
  linkTitle?: string;
  onLink?: MouseEventHandler;
  children?: React.ReactNode;
}

const SystemMessage: React.FC<SystemMessageProps> = ({
  level,
  children,
  displayCloseButton,
  closeConfirm,
  closeConfirmText,
  closeTitle,
  linkTitle,
  onClose,
  onLink,
}: SystemMessageProps) => {
  const handleOnClose = (e: React.MouseEvent): void => {
    if (typeof onClose === 'function') {
      onClose(e);
    }
  };
  const onCloseConfirm = (e: React.MouseEvent): void => {
    e.preventDefault();
    if (closeConfirm) {
      const confirmed = window.confirm(closeConfirmText);
      if (confirmed) {
        handleOnClose(e);
      }
    } else {
      handleOnClose(e);
    }
  };

  return (
    <div className={`system-message-${level} system-message-wrap`}>
      <div className="system-message-outer">
        <div className="system-message-inner">
          <div className="system-message-content">{children}</div>
          {(linkTitle || displayCloseButton) && (
            <div className="system-message-buttons">
              {linkTitle && (
                <button
                  title={linkTitle}
                  onClick={onLink}
                  className="btn btn-default btn-md no-disable"
                >
                  {linkTitle}
                </button>
              )}
              {displayCloseButton && (
                <button
                  className="system-message-close system-message-close-trigger"
                  title={closeTitle ? closeTitle : 'Close'}
                  onClick={onCloseConfirm}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SystemMessage;
