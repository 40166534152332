import MockAdapter from 'axios-mock-adapter';
import { AxiosRequestConfig } from 'axios';

export default function mock(mockApi: MockAdapter): void {
  mockApi.onPost('v1/oauth/token').reply((config: AxiosRequestConfig) => {
    if (
      config.data ===
      '{"grant_type":"authorization_code","code":"c1c615ed-52bc-4ba4-8a31-e422e33c095b"}'
    ) {
      return [
        200,
        {
          access_token: 'ce5b29ca-fef2-41d4-97ef-7d4adb68536a',
          refresh_token: 'fe60acf2-fc93-4e7c-bd13-dd761269fc81',
          token_type: 'bearer',
          expires_in: 3600,
          scope: 'api',
        },
      ];
    }

    return [401];
  });
}
