import MockAdapter from 'axios-mock-adapter/types';

export default function mockStatistics(mockApi: MockAdapter): void {
  const statistics: TransactionStatistics = {
    transaction: [
      {
        date: '2021-08-29',
        transactions: 3,
        payments: '220.0000',
        paymentFees: '6.6000',
        refunds: '-300.0000',
        refundFees: '250.0000',
      },
      {
        date: '2021-08-29',
        transactions: 3,
        payments: '220.0000',
        paymentFees: '6.6000',
        refunds: '-300.0000',
        refundFees: '250.0000',
      },
      {
        date: '2021-08-29',
        transactions: 3,
        payments: '220.0000',
        paymentFees: '6.6000',
        refunds: '-300.0000',
        refundFees: '250.0000',
      },
      {
        date: '2021-08-29',
        transactions: 3,
        payments: '220.0000',
        paymentFees: '6.6000',
        refunds: '-300.0000',
        refundFees: '250.0000',
      },
      {
        date: '2021-08-29',
        transactions: 3,
        payments: '220.0000',
        paymentFees: '6.6000',
        refunds: '-300.0000',
        refundFees: '250.0000',
      },
      {
        date: '2021-08-29',
        transactions: 3,
        payments: '220.0000',
        paymentFees: '6.6000',
        refunds: '-300.0000',
        refundFees: '250.0000',
      },
    ],
  };

  // eslint-disable-next-line @typescript-eslint/quotes
  const regExp = new RegExp(`v1/statistics\/?(.*)`);
  mockApi.onGet(regExp).reply(() => [200, statistics]);
}
