import { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';

export interface Options {
  size: number;
  className?: string;
}

export default function IconBase({
  options,
  viewBox,
  children,
}: {
  options: Options;
  viewBox: string;
  children: ReactNode;
}): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={options.size}
      height={options.size}
      className={classNames('stp-icon', options.className)}
      fill="currentColor"
      viewBox={viewBox}
    >
      {children}
    </svg>
  );
}
