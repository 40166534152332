const environment = process.env.NODE_ENV;

// TODO docs env vars
const configuration = Object.freeze({
  rootId: 'stpCrmRoot',

  apiBaseURL:
    environment === 'test'
      ? 'http://stp-test'
      : process.env.REACT_APP_API_BASE_URL,

  devMode:
    environment !== 'development'
      ? undefined
      : {
          applyMocks: process.env.REACT_APP_API_USE_MOCKS === '1',
          logApiRequests: process.env.REACT_APP_LOG_API_REQUESTS === '1',
        },

  isFlagRibbon: process.env.REACT_APP_FLAG_LAB_RIBBON === '1',
});

export default configuration;
