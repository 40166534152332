import { ReactElement } from 'react';
import IndexTabs from 'src/components/pages/Users/Index/IndexTabs';
import Refunds from 'src/components/pages/Users/Refunds/Refunds';

RefundsRoute.route = 'refunds';

export default function RefundsRoute(): ReactElement {
  return (
    <>
      <IndexTabs />
      <Refunds />
    </>
  );
}
