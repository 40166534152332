import { format, formatISO, isValid, toDate } from 'date-fns';

const STD_DATE = 'dd.MM.yyyy';

export function parseDate(value: unknown): undefined | Date {
  if (value instanceof Date) {
    return value;
  }
  if (typeof value === 'string') {
    const date = new Date(value);
    return isValid(date) ? toDate(date) : undefined;
  }
  return;
}

export function formatDate(
  value?: string | Date,
  formatString?: string,
): string {
  if (value instanceof Date) {
    return format(value, formatString ?? STD_DATE);
  } else {
    value = sanitizeDateString(value);
    if (!value) {
      return '';
    }
    const date = new Date(value);
    return format(date, formatString ?? STD_DATE);
  }
}

export function sanitizeDateString(
  value: undefined | string,
): undefined | string {
  if (!value) {
    return;
  }
  const date = new Date(value);
  if (!isValid(date)) {
    return;
  }
  return formatISO(date);
}
