import React from 'react';
import { CurrencySelect } from 'src/components/forms/CurrencySelect';
import EshopInternalsSelect from 'src/components/forms/EshopInternalsSelect';

export const StatisticsForm: React.FC = () => (
  <>
    <CurrencySelect hasDefaultDisabledOption name="currency" />
    <EshopInternalsSelect />
  </>
);
