import MockAdapter from 'axios-mock-adapter';
import { AxiosInstance } from 'axios';
import mockOauthToken from './oauthToken';
import mockInstallation from './installation';
import mockCurrencies from './currencies';
import mockStatistics from './statistics';
import mockCreditCheck from './creditCheck';
import mockPciQuestionnaire from './pciQuestionnaire';
import mockPos from 'src/services/api/mocks/pos';

export {
  mockOauthToken,
  mockInstallation,
  mockCurrencies,
  mockStatistics,
  mockCreditCheck,
  mockPciQuestionnaire,
};

export function applyAllMocks(axios: AxiosInstance): void {
  const mockApi = new MockAdapter(axios, { delayResponse: 300 });

  mockOauthToken(mockApi);
  mockInstallation(mockApi);
  mockCurrencies(mockApi);
  mockStatistics(mockApi);
  mockCreditCheck(mockApi);
  mockPciQuestionnaire(mockApi);
  mockPos(mockApi);
}
