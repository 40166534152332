import React, { ReactElement } from 'react';
import { BanRow } from 'src/components/pages/Users/Ban/BanRow';
import StateTable from 'src/components/pages/Users/StateTable';
import { ServiceState } from 'src/consts/ServiceState';

export const Ban: React.FC = () => (
  <StateTable
    states={[ServiceState.SHOPTET_BAN]}
    showState={false}
    content={(installation: Installation): ReactElement => (
      <BanRow installation={installation} key={installation.id} />
    )}
  />
);
