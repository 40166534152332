import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'src/components/common/Loading';
import { useStatistics } from 'src/services/hooks/useStatistics';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { Filter } from 'src/components/common/Filter/Filter';
import { StatisticRow } from 'src/components/pages/Statistics/StatisticRow';
import { StatisticsForm } from 'src/components/pages/Statistics/StatisticsForm';

export const Statistics: React.FC = () => {
  const { t } = useTranslation('statistics');
  const { loading, statistics, query, setQueryAndRefetchData } =
    useStatistics();

  const defaultValues = {
    currency: 'CZK',
    eshopInternals: '',
  };

  const [values] = useState<StatisticsFilter>(defaultValues);

  const transactionSchema = Yup.object().shape({
    currency: Yup.string(),
  });

  const submitFilter = (
    // data for filter are submitted inside Filter component
    values: StatisticsFilter,
    { setSubmitting }: FormikHelpers<StatisticsFilter>,
  ): void => {
    setSubmitting(false);
  };

  useEffect(() => {
    setQueryAndRefetchData(query);
  }, [query, setQueryAndRefetchData]);

  if (!statistics) {
    return <Loading />;
  }

  return (
    <Filter<StatisticsFilter, TransactionStatistic, never>
      schema={transactionSchema}
      values={values}
      filterForm={<StatisticsForm />}
      submitFilter={submitFilter}
      data={statistics.transaction}
      content={(statistic: TransactionStatistic): JSX.Element => (
        <StatisticRow
          statistic={statistic}
          currency={query.currency ?? 'CZK'}
          key={Math.random()}
        />
      )}
      headers={[
        { name: t('date') },
        { name: t('transactions') },
        { name: t('payments') },
        { name: t('paymentFees') },
        { name: t('refunds') },
        { name: t('refundFees') },
      ]}
      loading={loading}
      query={query as QueryFilter}
      setQuery={(query: QueryFilter): void =>
        setQueryAndRefetchData(query as StatisticsQuery)
      }
      defaultValues={defaultValues}
    />
  );
};
