import useAxios from 'axios-hooks';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InstallationsContext } from 'src/contexts/InstallationsContext';

interface Props {
  pos: Pos;
}

export const SwitchPosState: React.FC<Props> = ({ pos }: Props) => {
  const { t } = useTranslation();

  const { refetchData } = useContext(InstallationsContext);

  const [{ response }, patchService] = useAxios(
    {
      url: `v1/pos/terminals/${pos.id}/unassign`,
      method: 'post',
    },
    { manual: true },
  );

  const handleSwitchState = (): void => {
    const confirmed = window.confirm(t('pos:table.confirm'));

    if (confirmed) {
      patchService();
    }
  };

  useEffect(() => {
    if (response?.status === 204) {
      refetchData();
    }
  }, [response, refetchData]);

  return (
    <div>
      <button
        className="btn btn-md btn-action submit-js"
        onClick={handleSwitchState}
      >
        {t('pos:table.button')}
      </button>
    </div>
  );
};
