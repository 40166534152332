import React from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'src/components/forms/Input';

export const PosFilterForm: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <Input name="eshopId" label={t('users:filter.common.projectId')} />
      <Input name="poiId" label={t('pos:table.poiId')} />
      <Input name="storeId" label={t('pos:table.storeIdAdyen')} />
    </>
  );
};
