import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EshopInternals } from 'src/consts/EshopInternals';
import { Select, SelectOptionsType } from './Select';

export default function EshopInternalsSelect(): ReactElement {
  const { t } = useTranslation();

  const internalOptions: SelectOptionsType[] = useMemo(() => {
    const options = [{ id: '', value: '' }];
    options.push(
      ...Object.keys(EshopInternals)
        .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
        .map(internal => ({
          id: EshopInternals[internal],
          value: internal,
        })),
    );
    return options;
  }, []);

  return (
    <Select
      hasDefaultDisabledOption
      disableEmpty={false}
      options={internalOptions}
      name="eshopInternals"
      label={t('users:filter.common.eshopInternals')}
    />
  );
}
