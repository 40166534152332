import { ReactElement } from 'react';
import IndexTabs from 'src/components/pages/Users/Index/IndexTabs';
import { Suspend } from 'src/components/pages/Users/Suspend/Suspend';

SuspendedRoute.route = 'suspended';

export default function SuspendedRoute(): ReactElement {
  return (
    <>
      <IndexTabs />
      <Suspend />
    </>
  );
}
