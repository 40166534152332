import { ReactElement } from 'react';
import IndexTabs from 'src/components/pages/Users/Index/IndexTabs';
import { NotApproved } from 'src/components/pages/Users/NotApproved/NotApproved';

UnapprovedRoute.route = 'unapproved';

export default function UnapprovedRoute(): ReactElement {
  return (
    <>
      <IndexTabs />
      <NotApproved />
    </>
  );
}
