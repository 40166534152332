import React, {
  ChangeEvent,
  FormEvent,
  MouseEvent,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface PaginationProps {
  count: number;
  offset: number;
  limit: number;
  onOffsetChange(offset: number): void;
  onLimitChange(limit: number, offset: number): void;
}

const limitOptions = [25, 50, 75, 100];

export const Pagination: React.FC<PaginationProps> = ({
  count,
  offset,
  limit,
  onOffsetChange,
  onLimitChange,
}: PaginationProps): JSX.Element => {
  const { t } = useTranslation('users');
  const page = useMemo(
    () => (offset ? offset / limit + 1 : 1),
    [offset, limit],
  );
  const [customPage, setCustomPage] = useState<string>('');
  const [displayCustomPageError, setDisplayCustomPageError] =
    useState<boolean>(false);

  const enablePagination = count > limit;
  const lastPage = Math.ceil(count / limit);
  const clamp = (min: number, max: number, value: number): number =>
    Math.min(Math.max(value, min), max);

  if (!enablePagination) {
    return <></>;
  }

  const updatePaginationQuery = (
    event: React.MouseEvent | React.FormEvent,
    pageNumber: number,
  ): void => {
    event.preventDefault();
    onOffsetChange((pageNumber - 1) * limit);
  };

  const updatePaginationQueryCustomPage = (
    e: React.MouseEvent | React.FormEvent,
  ): void => {
    const numericValue = parseInt(customPage);
    if (
      !isNaN(numericValue) &&
      numericValue <= lastPage &&
      numericValue > 0 &&
      numericValue === parseFloat(customPage)
    ) {
      setCustomPage('');
      setDisplayCustomPageError(false);
      updatePaginationQuery(e, numericValue);
    } else {
      e.preventDefault();
      setDisplayCustomPageError(true);
    }
  };

  const renderPrevNext = (navigation: 'prev' | 'next'): JSX.Element => (
    <>
      <a
        className={classNames(
          `btn btn-md btn-muted strong pagination__pages__${navigation}`,
          {
            'right-1em': navigation === 'prev',
            'left-1em': navigation === 'next',
          },
        )}
        href="#"
        title={t(`pagination.${navigation}`)}
        onClick={(e: React.MouseEvent): void => {
          const pageNumber =
            navigation === 'prev'
              ? Math.max(page - 1, 1)
              : Math.min(page + 1, lastPage);

          updatePaginationQuery(e, pageNumber);
        }}
      >
        {t(`pagination.${navigation}`)}
      </a>
      {navigation === 'prev' && <>&nbsp;</>}
    </>
  );

  const renderPaginationItem = (pageNumber: number): JSX.Element => {
    if (pageNumber === page) {
      return <strong className="btn btn-md">{pageNumber}</strong>;
    }

    let title;
    if (pageNumber === 1) {
      title = t('pagination.first');
    } else if (pageNumber === lastPage) {
      title = t('pagination.last');
    }

    return (
      <>
        <a
          className="btn btn-md btn-muted"
          href="#"
          title={title}
          onClick={(e: React.MouseEvent): void =>
            updatePaginationQuery(e, pageNumber)
          }
        >
          {pageNumber}
        </a>
        &nbsp;
      </>
    );
  };

  return (
    <div className="pagination">
      <div className="pagination__founds">
        <strong>{count}</strong>
        &nbsp;
        {t('pagination.totalLabel', { count })}
      </div>
      <div className="pagination__content">
        <div className="pagination__limits">
          <form
            className="formListing js-formListing"
            action="#"
            onSubmit={(event: FormEvent<HTMLFormElement>): void =>
              event.preventDefault()
            }
          >
            <label className="formListing__label" htmlFor="listingLimit">
              {t('pagination.limit')}
            </label>
            &nbsp;
            <select
              id="listingLimit"
              className="js-formListing__select formListing__select"
              name="itemsPerPage"
              defaultValue={limit}
              onChange={(e: ChangeEvent<HTMLSelectElement>): void => {
                const newLimit = parseInt(e.target.value);
                if (!isNaN(newLimit)) {
                  const checkedValue = clamp(
                    limitOptions[0],
                    limitOptions[3],
                    newLimit,
                  );
                  onLimitChange(checkedValue, 0);
                }
              }}
            >
              {limitOptions.map(
                (value: number): JSX.Element => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ),
              )}
            </select>
          </form>
        </div>
        <div className="pagination__pages">
          {page > 1 && renderPrevNext('prev')}
          {page - 3 >= 1 && (
            <>
              {renderPaginationItem(1)}
              ...
            </>
          )}
          {page - 2 >= 1 && renderPaginationItem(page - 2)}
          {page - 1 >= 1 && renderPaginationItem(page - 1)}
          {renderPaginationItem(page)}
          {page + 1 <= lastPage && renderPaginationItem(page + 1)}
          {page + 2 <= lastPage && renderPaginationItem(page + 2)}
          {page + 3 <= lastPage && (
            <>
              ...
              {renderPaginationItem(lastPage)}
            </>
          )}
          {page < lastPage && renderPrevNext('next')}
        </div>
        <div className="pagination__jump">
          <form
            className="formJumpToPage js-formJumpToPage"
            action="#"
            onSubmit={(event: FormEvent<HTMLFormElement>): void => {
              updatePaginationQueryCustomPage(event);
            }}
          >
            <label className="formJumpToPage__label" htmlFor="from">
              {t('pagination.showPage')}
            </label>
            &nbsp;
            <input
              className={classNames(
                'focused dont-display-exit-confirm formJumpToPage__input js-formJumpToPage__input',
                {
                  'validation-error': displayCustomPageError,
                },
              )}
              type="text"
              name="from"
              onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                setCustomPage(e.target.value)
              }
            />
            &nbsp;
            <a
              className="btn btn-md btn-action font-14px shp-arrow2 submit-js"
              href="#"
              title=""
              onClick={(e: MouseEvent): void =>
                updatePaginationQueryCustomPage(e)
              }
            />
          </form>
        </div>
      </div>
    </div>
  );
};
