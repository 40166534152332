import React from 'react';
import { ProcessingRow } from 'src/components/pages/Users/Processing/ProcessingRow';
import StateTable from 'src/components/pages/Users/StateTable';
import { ServiceState } from 'src/consts/ServiceState';

export const Processing: React.FC = () => (
  <StateTable
    states={[
      ServiceState.ADYEN_APPROVAL,
      ServiceState.ADYEN_ACTIVATION,
      ServiceState.SHOPTET_PCI,
      ServiceState.ADYEN_CREATE_STORE,
      ServiceState.ADYEN_PAYMENT_METHOD,
      ServiceState.SHOPTET_PAYMENT_METHOD,
      ServiceState.ADYEN_ERROR,
      ServiceState.SHOPTET_ERROR,
    ]}
    content={(installation: Installation): JSX.Element => (
      <ProcessingRow installation={installation} key={installation.id} />
    )}
  />
);
