import MockAdapter from 'axios-mock-adapter';

export default function mock(mockApi: MockAdapter): void {
  mockApi.onGet('v1/installation').reply(() => [
    200,
    {
      items: [
        {
          id: 'd5bca94c-5e7c-4ef1-875b-4da24d64aed0',
          eshopId: '12345',
          eshopUrl: 'https://12345.myshoptet.cz',
          state: 'NOT_ORDERED',
          activationDate: null,
          applicationDate: '2021-05-23T16:15:32.403Z',
          updatedAt: '2021-05-25T09:10:32.403Z',
          billingCompany: 'Karel IV Industries s.r.o',
          contactName: 'Karel IV',
          cin: '123456789',
          allowedStates: [],
          service: 'card',
          eshopInternals: 'cs',
        },
        {
          id: '2ee50891-5458-4c07-950b-399c2b71db10',
          eshopId: '54321',
          eshopUrl: 'https://54321.myshoptet.cz',
          state: 'NOT_ORDERED',
          activationDate: null,
          applicationDate: '2021-05-23T16:15:32.403Z',
          updatedAt: '2021-05-25T09:10:32.403Z',
          billingCompany: null,
          contactName: null,
          allowedStates: [],
          service: 'card',
          eshopInternals: 'cs',
        },
        {
          id: '652d72b8-2eb5-4eff-8802-8d69d892569c',
          state: 'ADYEN_CREATE_STORE',
          eshopId: '99999',
          eshopUrl: 'https://99999.myshoptet.com/',
          billingCompany: null,
          contactName: null,
          allowedStates: [],
          service: 'card',
          eshopInternals: 'sk',
        },
        {
          id: 'c98b1a19-ba75-4910-b7f7-7dfd6c691f63',
          eshopId: '88888',
          eshopUrl: 'https://88888.myshoptet.cz',
          state: 'NOT_ORDERED',
          activationDate: null,
          applicationDate: '2021-05-23T16:15:32.403Z',
          updatedAt: '2021-05-25T09:10:32.403Z',
          billingCompany: 'Spolecnost s.r.o',
          contactName: 'Majitel Spolecnosti',
          allowedStates: [],
          service: 'card',
          eshopInternals: 'cs',
        },
        {
          id: '45d3baa9-0173-462d-8d4a-61d3869e808a',
          eshopId: '11223',
          eshopUrl: 'https://www.nahodny-eshop.domena',
          state: 'ADYEN_ERROR',
          activationDate: null,
          applicationDate: '2021-05-23T16:15:32.403Z',
          updatedAt: '2021-05-25T09:10:32.403Z',
          errorDescription: 'something went horribly wrong',
          billingCompany: 'Spolecnost a.s.',
          contactName: 'Majitel',
          allowedStates: [],
          service: 'card',
          eshopInternals: 'cs',
        },
        {
          id: '61e22229-02c2-4d97-9467-db1ba355ce96',
          eshopId: '11223',
          eshopUrl: 'https://www.nahodny-eshop2.domena',
          state: 'SHOPTET_PCI',
          activationDate: null,
          applicationDate: '2021-05-23T16:15:32.403Z',
          updatedAt: '2021-05-25T09:10:32.403Z',
          billingCompany: 'Company a.s.',
          contactName: 'Owner',
          allowedStates: [],
          service: 'card',
          eshopInternals: 'cs',
        },
        {
          activationDate: null,
          allowedStates: [],
          applicationDate: null,
          billingCompany: 'Shoptet, a.s.',
          contactName: 'Mr. Cimrman',
          eshopId: 456869,
          eshopUrl: 'https://456869.myshoptet.cz',
          id: '847694576838',
          service: 'pb',
          state: 'NOT_ORDERED',
          updatedAt: '2022-06-22T05:47:09.000Z',
          eshopInternals: 'cs',
        },
        {
          activationDate: null,
          allowedStates: [],
          applicationDate: null,
          billingCompany: 'Darina Majer',
          contactName: 'Jára Cimrman',
          eshopId: 481980,
          eshopUrl: 'https://481980.myshoptet.cz',
          id: '8745391627579',
          service: 'pb',
          state: 'NOT_ORDERED',
          updatedAt: '2022-06-22T05:47:09.000Z',
          eshopInternals: 'hu',
        },
        {
          activationDate: null,
          allowedStates: [],
          applicationDate: null,
          billingCompany: 'Darina Majer',
          contactName: 'Jára Cimrman',
          eshopId: 481980,
          eshopUrl: 'https://481980.myshoptet.cz',
          id: '874539162757',
          service: 'pos',
          state: 'NOT_ORDERED',
          updatedAt: '2022-06-22T05:47:09.000Z',
          eshopInternals: 'cs',
        },
      ],
    },
  ]);
}
