import { Application } from 'src/models/index';
import {
  fallbackLocale,
  knownLanguages,
  payCrmPageRouteTranslations,
  payCrmPageTranslations,
} from 'src/i18n';

interface Path {
  routePath: string;
  segments: PathSegment[];
}

type PathSegment =
  | { isParam: true; paramName: string }
  | { isParam?: false; segment: string };

export default class PageRoutes {
  readonly routesByName: Record<string, undefined | Application.Router.Route> =
    {};

  readonly routesByPathname: Record<
    string,
    undefined | Application.Router.Route
  > = {};

  readonly pathsWithParams: Path[];

  constructor(page: Application.Router.PageComponent) {
    const canonicalPageTranslations = payCrmPageTranslations(
      fallbackLocale,
      page.page,
    );
    for (const routeComponent of page.routes) {
      const canonicalPath =
        canonicalPageTranslations?.routes?.[routeComponent.route]?.path;
      if (!canonicalPath) {
        continue;
      }

      const route: Application.Router.Route = {
        page: page.page,
        name: routeComponent.route,
        component: routeComponent,
      };

      this.routesByName[route.name] = route;

      for (const language of knownLanguages) {
        const path = payCrmPageRouteTranslations(
          language,
          page.page,
          routeComponent.route,
        )?.path;
        if (path) {
          this.routesByPathname[path] = route;
        }
      }
    }

    this.pathsWithParams = Object.keys(this.routesByPathname)
      .filter(path => path.includes(':'))
      .map(parsePathWithParams);
  }

  getRouteAndParamsFromPath(
    path: string,
  ):
    | undefined
    | [Application.Router.Route, undefined | Application.Router.Params] {
    if (path.length === 0) {
      path = '/';
    } else if (path.length > 1 && path.endsWith('/')) {
      path = path.slice(0, path.length - 1);
    }
    const hitWithoutParams = this.routesByPathname[path];
    if (hitWithoutParams) {
      return [hitWithoutParams, undefined];
    }

    for (const { routePath, segments } of this.pathsWithParams) {
      const route = this.routesByPathname[routePath];
      if (route) {
        const validParams = extractPramsFromPath(path, segments);
        if (validParams) {
          return [route, validParams];
        }
      }
    }

    return;
  }

  resolvePathWithParams(
    routePath: string,
    params: undefined | Application.Router.Params,
  ): string {
    const path = this.pathsWithParams.find(
      path => path.routePath === routePath,
    );
    if (!path) {
      return routePath;
    }
    const segments = path.segments.map(segment => {
      if (!segment.isParam) {
        return segment.segment;
      }

      const value = params?.[segment.paramName];
      if (!value) {
        throw new Error(`STP [ERR] missing param ${segment.paramName}`);
      }
      params[segment.paramName] = undefined;
      return value;
    });
    return `/${segments.join('/')}`;
  }
}

function parsePathWithParams(path: string): Path {
  const segments = path
    .slice(1)
    .split('/')
    .map<PathSegment>(segment => {
      const interpolation = segment.startsWith(':');
      if (interpolation) {
        return {
          isParam: true,
          paramName: segment.slice(1),
        };
      } else {
        return {
          isParam: false,
          segment,
        };
      }
    });
  return { routePath: path, segments };
}

function extractPramsFromPath(
  path: string,
  segments: PathSegment[],
): undefined | Application.Router.Params {
  const pathnameSegments = path.slice(1).split('/');
  if (pathnameSegments.length !== segments.length) {
    return;
  }
  const list = segments;
  for (let index = 0; index < list.length; index += 1) {
    const segment = list[index];
    if (!segment.isParam && pathnameSegments[index] !== segment.segment) {
      return;
    }
  }
  const params: Application.Router.Params = {};
  list.forEach((segment, index) => {
    if (segment.isParam) {
      params[segment.paramName] = pathnameSegments[index];
    }
  });
  return params;
}
