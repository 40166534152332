import { ReactElement } from 'react';
import { ActiveRow } from 'src/components/pages/Users/Active/ActiveRow';
import StateTable from 'src/components/pages/Users/StateTable';
import { ServiceState } from 'src/consts/ServiceState';

export const Active: React.FC = () => (
  <StateTable
    states={[ServiceState.ACTIVE]}
    showState={false}
    showUpdateDate={false}
    showActivationDate={true}
    defaultSort="applicationDate"
    content={(installation: Installation): ReactElement => (
      <ActiveRow installation={installation} key={installation.id} />
    )}
  />
);
