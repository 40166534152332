import MockAdapter from 'axios-mock-adapter';

export default function mock(mockApi: MockAdapter): void {
  mockApi.onGet('v1/list/currencies').reply(() => [
    201,
    {
      currencies: ['CZK', 'EUR'],
    },
  ]);
}
