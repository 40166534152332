import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'src/components/forms/Select';
import { useCurrencies } from 'src/services/hooks/useCurrencies';

interface CurrencySelectProps {
  name: string;
  label?: string;
  hasDefaultDisabledOption?: boolean;
  prependEmpty?: boolean;
}

export const CurrencySelect: React.FC<CurrencySelectProps> = ({
  name,
  label,
  hasDefaultDisabledOption = false,
  prependEmpty,
}: CurrencySelectProps) => {
  const { t } = useTranslation('form');
  const { currencies } = useCurrencies();

  const options = useMemo(() => {
    const list = currencies.map((currency: string) => ({
      id: currency,
      value: currency,
    }));
    if (prependEmpty) {
      list.unshift({ id: '', value: '' });
    }
    return list;
  }, [currencies, prependEmpty]);

  return (
    <Select
      hasDefaultDisabledOption={hasDefaultDisabledOption}
      options={options}
      name={name}
      label={label ?? t('currency')}
      disableEmpty={!prependEmpty}
    />
  );
};
