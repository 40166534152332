import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, SelectOptionsType } from 'src/components/forms/Select';
import Input from 'src/components/forms/Input';
import EshopInternalsSelect from 'src/components/forms/EshopInternalsSelect';
import ServiceSelect from 'src/components/forms/ServiceSelect';

interface UsersFilterFormProps {
  stateOptions?: SelectOptionsType[];
}

export const UsersFilterForm: React.FC<UsersFilterFormProps> = ({
  stateOptions: stateOptions,
}: UsersFilterFormProps): JSX.Element => {
  const { t } = useTranslation('users');

  return (
    <>
      <Input name="eshopId" label={t('filter.common.projectId')} />
      <Input name="billingCompany" label={t('filter.common.billingCompany')} />
      <Input name="contactName" label={t('filter.common.contactName')} />
      <ServiceSelect />
      <EshopInternalsSelect />
      {stateOptions && (
        <Select
          hasDefaultDisabledOption
          options={stateOptions}
          name="state"
          label={t('filter.common.state')}
        />
      )}
    </>
  );
};
