import { ReactElement, ReactNode, useCallback, useEffect } from 'react';
import { Application } from 'src/models';
import i18n from 'i18next';
import { initI18n } from 'src/i18n';
import logger from 'src/services/logger';

export default function LocalizationProvider({
  state,
  update,
  children,
}: {
  state: Application.State;
  update: Application.Update;
  children: ReactNode;
}): ReactElement {
  const updateLocalizationState = useCallback(
    (phase: Application.BootPhase): void => {
      update(state => ({
        ...state,
        stack: Application.mutations.updateStackPhase({
          ...state.stack,
          localization: phase,
        }),
      }));
    },
    [update],
  );

  useEffect(() => {
    setupLocalization(state)
      .then(() => {
        updateLocalizationState(Application.BootPhase.UP);
      })
      .catch(() => updateLocalizationState(Application.BootPhase.DOWN));
    // only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>{state.stack.localization === Application.BootPhase.UP && children}</>
  );
}

async function setupLocalization(state: Application.State): Promise<void> {
  try {
    await initI18n();
    await i18n.changeLanguage(state.settings.locale);
  } catch (error: any) {
    logger.log('STP:LOCALIZATION: setup failed');
    logger.error(error);
    throw error;
  }
}
