import Loading from 'src/components/common/Loading';

interface TableProps<P, N> {
  loading?: boolean;
  content(renderContentRow: P): JSX.Element | JSX.Element[];
  data: P[];
  headers: HeaderFilter[];
  never?: N;
}

export const Table = <P, N extends TableProps<P, N>>({
  loading,
  content,
  data,
  headers,
}: TableProps<P, N>): JSX.Element => {
  const contentRows = data.map(content);

  const contentRender: JSX.Element[] | null = data.length
    ? contentRows.flat(1)
    : null;

  return (
    <div className={'table-holder'}>
      {loading && <Loading />}
      <table className="std-table std-table-listing">
        <thead>
          <tr>
            {headers.map(({ name }: HeaderFilter) => (
              <th key={name}>{name}</th>
            ))}
          </tr>
        </thead>
        <tbody>{contentRender}</tbody>
      </table>
    </div>
  );
};
