import { SwitchPosState } from 'src/components/pages/Users/common/SwitchPosState';

interface Props {
  pos: Pos;
}

export const PosRow: React.FC<Props> = ({ pos }: Props) => (
  <tr>
    <td>{pos.store.installation.eshopId}</td>
    <td>{pos.id}</td>
    <td>{pos.poiId}</td>
    <td>{pos.store.name}</td>
    <td>{pos.store.id}</td>
    <td>{pos.store.storeId}</td>
    <td>
      <SwitchPosState pos={pos} />
    </td>
  </tr>
);
