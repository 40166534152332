import { FormikHelpers, FormikValues } from 'formik';
import {
  omitObjectKeysExceptSelected,
  removeEmptyObjectValues,
} from 'src/components/shared/utils/functionHelpers';
import {
  setUrlParams,
  storageUrlKeyGenerator,
} from 'src/shared/localStorage/urlParams';
import { useCallback } from 'react';

export function useSaveFilterParams<T extends FormikValues>(
  values: T,
  submitFilter: (values: T, props: FormikHelpers<T>) => void,
  query: QueryFilter,
  setQuery: (query: QueryFilter) => void,
): {
  submitFilterSaveParams(values: T, props: FormikHelpers<T>): void;
  setQueryAndSaveParams(query: QueryFilter): void;
} {
  const setQueryAndSaveParams = useCallback(
    <T>(localQuery: QueryFilter, localValues?: T, isSubmit?: boolean): T => {
      const mainQuery = localQuery;
      const mainValues = localValues ?? values;
      const url = new URL(window.location.href.split('?')[0]);
      const params = url.searchParams;
      const hash = window.location.hash ? window.location.hash : '';
      const newValues = removeEmptyObjectValues(mainValues) as T;
      const allQueries = {
        ...(isSubmit
          ? (omitObjectKeysExceptSelected<(keyof OmitQuery)[]>(mainQuery, [
              'state',
              'sort',
              'limit',
              'offset',
            ]) as OmitQuery)
          : mainQuery),
        ...newValues,
      };
      Object.keys(allQueries).forEach((key: string) =>
        params.append(key, allQueries[key]),
      );
      const newUrl = url.toString();
      window.history.replaceState(
        null,
        '',
        `${newUrl}${newUrl.includes(hash) ? '' : hash}`,
      );
      setUrlParams<T>(allQueries, storageUrlKeyGenerator());
      setQuery(allQueries as QueryFilter);
      return newValues;
    },
    [setQuery, values],
  );

  const submitFilterSaveParams = useCallback(
    (localValues: T, props: FormikHelpers<T>): void => {
      const newValues = setQueryAndSaveParams(query, localValues, true);
      submitFilter(newValues, props);
    },
    [query, setQueryAndSaveParams, submitFilter],
  );

  return {
    submitFilterSaveParams,
    setQueryAndSaveParams,
  };
}
