import { createElement, ReactElement } from 'react';
import routes from 'src/components/routes/Overview/routes';
import { Pages } from 'src/consts/Pages';
import { Application } from 'src/models';

OverviewPage.page = Pages.overview;
OverviewPage.routes = routes;

export default function OverviewPage({
  routeComponent,
}: Application.Router.PageComponentProps): ReactElement {
  return <>{createElement(routeComponent)}</>;
}
