import { Application } from 'src/models';
import { fallbackLocale } from 'src/i18n';

export function defaultState(): Application.State {
  return {
    stack: {
      state: Application.BootPhase.INITIAL,
      localization: Application.BootPhase.INITIAL,
      apiClient: Application.BootPhase.INITIAL,
      router: {
        state: Application.BootPhase.INITIAL,
      },
    },
    settings: {
      locale: fallbackLocale,
    },
  };
}
