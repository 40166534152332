import { Application } from 'src/models/index';
import {
  fallbackLocale,
  knownLanguages,
  payCrmPageRouteTranslations,
  payCrmPageTranslations,
} from 'src/i18n';
import { ReactElement } from 'react';
import { Pages } from 'src/consts/Pages';
import { pages } from 'src/components/routes';

export default class Configuration {
  readonly pagesByName: Record<string, undefined | Application.Router.Page> =
    {};

  readonly pagesByPathnameSuffix: Record<
    string,
    undefined | Application.Router.Page
  > = {};

  constructor() {
    for (const pageComponent of pages) {
      const canonicalPathSuffix = payCrmPageTranslations(
        fallbackLocale,
        pageComponent.page,
      )?.pathSuffix;
      if (!canonicalPathSuffix) {
        return;
      }

      const page: Application.Router.Page = {
        name: pageComponent.page,
        component: pageComponent,
        routes: new Application.Router.PageRoutes(pageComponent),
      };

      this.pagesByName[page.name] = page;

      for (const language of knownLanguages) {
        const pathSuffix = payCrmPageTranslations(
          language,
          pageComponent.page,
        )?.pathSuffix;
        if (pathSuffix) {
          this.pagesByPathnameSuffix[pathSuffix] = page;
        }
      }
    }
  }

  static assurePresence(
    config?: Application.Router.Configuration,
  ): Application.Router.Configuration {
    if (!config) {
      throw new Error('STP: [ERR] router configuration not present');
    }
    return config;
  }

  getRouteForLocation(
    location: Location,
  ): undefined | Application.Router.RouteState {
    const page = this.getPageFromPath(location.pathname);
    if (!page) {
      return;
    }

    const searchParams = location.search.length
      ? Object.fromEntries(new URLSearchParams(location.search))
      : undefined;

    const [routePath, routeSearch] = (
      location.hash.startsWith('#') ? location.hash.slice(1) : ''
    ).split('?');
    const [route, routeParams] =
      page.routes.getRouteAndParamsFromPath(routePath) ?? [];
    if (!route) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const routeSearchParams = routeSearch?.length
      ? Object.fromEntries(new URLSearchParams(routeSearch))
      : undefined;

    const result: Application.Router.RouteState = {
      page: page.name,
      route: route.name,
    };
    if (searchParams || routeParams || routeSearchParams) {
      result.params = { ...searchParams, ...routeSearchParams, ...routeParams };
    }
    return result;
  }

  getPageFromPath(path: string): undefined | Application.Router.Page {
    if (path.endsWith('/')) {
      path = path.slice(0, path.length - 1);
    }
    const suffixMatch = path.match(/^\/admin\/shoptet-pay-(.+)\/?$/);
    if (!suffixMatch) {
      return;
    } else {
      return this.pagesByPathnameSuffix[suffixMatch[1]];
    }
  }

  getPageComponentAndProps(route: Application.Router.RouteState):
    | undefined
    | {
        component(props: Application.Router.PageComponentProps): ReactElement;
        props: Application.Router.PageComponentProps;
      } {
    const page = this.pagesByName[route.page];
    const pageComponent = page?.component;
    const routeComponent = page?.routes.routesByName[route.route]?.component;
    if (pageComponent && routeComponent) {
      return {
        component: pageComponent,
        props: {
          routeComponent,
          params: route.params,
        },
      };
    } else {
      return;
    }
  }

  getFullPathForRoute(
    routeState: Application.Router.RouteState,
    locale: string,
  ): string {
    const { page: pageName, route, params: originalParams } = routeState;
    const page = this.pagesByName[pageName];
    if (!page) {
      throw new Error(`STP [ERR] undefined page ${page}`);
    }
    const pagePath = localizePagePath(pageName, locale);
    let routePath = localizeRoutePath(pageName, route, locale);
    const params = { ...originalParams };
    routePath = page.routes.resolvePathWithParams(routePath, params);
    const paramsSearch = paramsToSearch(params);
    const path = `${pagePath}/#${routePath}`;
    if (paramsSearch) {
      return `${path}?${paramsSearch}`;
    } else {
      return path;
    }
  }
}

function localizePagePath(page: Pages, locale: string): string {
  const pathSuffix =
    payCrmPageTranslations(locale, page)?.pathSuffix ??
    payCrmPageTranslations(fallbackLocale, page)?.pathSuffix;
  if (!pathSuffix || typeof pathSuffix !== 'string') {
    throw new Error(`STP [ERR] path translation for ${page} missing`);
  }
  return `/admin/shoptet-pay-${pathSuffix}`;
}

function localizeRoutePath(page: Pages, route: string, locale: string): string {
  const path =
    payCrmPageRouteTranslations(locale, page, route)?.path ??
    payCrmPageRouteTranslations(fallbackLocale, page, route)?.path;
  if (!path || typeof path !== 'string') {
    throw new Error(`STP [ERR] path translation for ${page}/${route} missing`);
  }
  return path;
}

function paramsToSearch(params: Application.Router.Params): undefined | string {
  if (Object.keys(params).length === 0) {
    return;
  }

  const searchParams = new URLSearchParams();
  const keys = Object.keys(params);
  if (!keys.length) {
    return;
  }
  for (const prop of keys) {
    const value = params[prop];
    if (value) {
      searchParams.append(prop, value);
    }
  }
  return searchParams.toString();
}
