import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

interface NoContentProps {
  text?: string;
}

export const NoContent: React.FC<NoContentProps> = ({
  text = 'noContent',
}: PropsWithChildren<NoContentProps>) => {
  const { t } = useTranslation('filter');

  return <p className="std-nothing-found">{t(text)}</p>;
};
