import { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import StpIcon from 'src/components/vendor/StpIcon';

export default function StpAlert({
  variant,
  title,
  className,
  children,
}: {
  variant: 'success' | 'error';
  title?: string;
  className?: string;
  children: ReactNode;
}): ReactElement {
  const iconName = variant === 'success' ? 'check' : 'fillAlert';
  return (
    <div className={classNames('stp-alert', `__${variant}`, className)}>
      <div className="flex flex-direction-row items-center">
        <div className="stp-alert--iconbox">
          <StpIcon icon={iconName} size={24} />
        </div>
        <div className="stp-alert--inner font-bold">
          {title && <div className="stp-alert--title font-normal">{title}</div>}
          {children}
        </div>
      </div>
    </div>
  );
}
