import { useEffect, useLayoutEffect, useMemo, useState } from 'react';

export function useStaticMemo<V>(fn: () => V): V {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(fn, []);
}

export function useEffectOnConnected(fn: () => void): void {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fn, []);
}

export function useEffectOnce(trigger: boolean, effect: () => void): void {
  const [fired, setFired] = useState(false);
  useEffect(() => {
    if (!fired && trigger) {
      setFired(true);
      effect();
    }
    // deliberately ignore effect, it should be static
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fired, trigger]);
}

export function useLayoutEffectOnConnected(fn: () => void): void {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(fn, []);
}
