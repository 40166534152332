import useAxios from 'axios-hooks';
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AdyenRefundState } from 'src/consts/RefundState';
import { InstallationsContext } from 'src/contexts/InstallationsContext';

interface SwitchStateProps {
  options: AdyenRefundState[];
  refund: Refund;
}

export const SwitchRefundState: React.FC<SwitchStateProps> = ({
  options,
  refund,
}: SwitchStateProps) => {
  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState('0');

  const { refetchData } = useContext(InstallationsContext);

  const [{ response }, patchService] = useAxios(
    {
      url: `v1/refunds/${refund.id}`,
      method: 'patch',
      data: {
        state: selectedOption,
      },
    },
    { manual: true },
  );

  const handleSwitchState = (): void => {
    if (selectedOption === '0') {
      return;
    }
    const confirmed = window.confirm(
      t('users:table.actions.confirm', {
        state: t(`refunds:state.${selectedOption}`),
      }),
    );

    if (confirmed) {
      patchService();
    }
  };

  useEffect(() => {
    if (response?.status === 200) {
      refetchData();
    }
  }, [response, refetchData]);

  const isDisabled = options.length === 0;

  return isDisabled ? (
    <></>
  ) : (
    <div style={{ display: 'flex', gap: '5px' }}>
      <select
        style={{ width: '150px' }}
        value={selectedOption}
        onChange={(e): void => {
          setSelectedOption(e.target.value);
        }}
      >
        <option value={'0'} />
        {options.map((option: string) => (
          <option id={option} value={option} key={option}>
            {t(`refunds:state.${option}`)}
          </option>
        ))}
      </select>
      <button
        className="btn btn-md btn-action submit-js"
        onClick={handleSwitchState}
      >
        {t('users:table.actions.change')}
      </button>
    </div>
  );
};
