import { Application } from 'src/models';

export type Update = (fn: (state: State) => State) => void;

export interface State {
  stack: {
    state: BootPhase;
    localization: BootPhase;
    apiClient: BootPhase;
    router: {
      state: BootPhase;
      configuration?: Application.Router.Configuration;
    };
  };
  settings: {
    locale: string;
    authCode?: string;
  };
  flags?: {
    isFailedGlobalRequest?: boolean;
  };
  route?: Application.Router.RouteState;
}

export enum BootPhase {
  INITIAL = 'INITIAL',
  UP = 'UP',
  DOWN = 'DOWN',
}
