import { useTranslation } from 'react-i18next';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Decimal } from 'decimal.js';

export function useCurrencyFormat(): {
  formatCurrency(amount: number, currency: string): JSX.Element;
  getDisplayBalance(
    value: number,
    currency?: string,
    endLabel?: string,
  ): JSX.Element;
  getDisplayValue(value: string, currency?: string): JSX.Element;
} {
  const { i18n } = useTranslation();

  const formatCurrency = (amount: number): JSX.Element => {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };

    return (
      <>{Intl.NumberFormat([i18n.language, 'en'], options).format(amount)}</>
    );
  };

  const getDisplayBalance = (
    value: number,
    currency?: string,
    endLabel?: string,
  ): JSX.Element => {
    const currencySymbol = currency ? (
      <>&nbsp;{getSymbolFromCurrency(currency)}</>
    ) : (
      ''
    );
    if (value === 0) {
      return (
        <>
          {formatCurrency(value)}
          {currencySymbol}
          {!!endLabel && ` ${endLabel}`}
        </>
      );
    }

    return (
      <>
        {formatCurrency(value)}
        {currencySymbol}
        {!!endLabel && ` ${endLabel}`}
      </>
    );
  };

  const getDisplayValue = (value: string, currency?: string): JSX.Element => {
    const d = new Decimal(value);
    const currencySymbol = currency ? (
      <>&nbsp;{getSymbolFromCurrency(currency)}</>
    ) : (
      ''
    );
    return (
      <>
        {d.isPos() && !d.isZero() && <>+</>}
        {formatCurrency(d.toNumber())}
        {currencySymbol}
      </>
    );
  };

  return {
    formatCurrency,
    getDisplayBalance,
    getDisplayValue,
  };
}
