import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrencyFormat } from 'src/services/hooks/useCurrencyFormat';

interface StatisticRowProps {
  statistic: TransactionStatistic;
  currency: string;
}

export const StatisticRow: React.FC<StatisticRowProps> = ({
  statistic,
  currency,
}: StatisticRowProps) => {
  const { t } = useTranslation('statistics');
  const { getDisplayBalance } = useCurrencyFormat();

  return (
    <tr>
      <td>
        {t('table.date', {
          date: new Date(statistic.date),
        })}
      </td>
      <td>{statistic.transactions}</td>
      <td>{getDisplayBalance(Number(statistic.payments), currency)}</td>
      <td>{getDisplayBalance(Number(statistic.paymentFees), currency)}</td>
      <td>{getDisplayBalance(Number(statistic.refunds), currency)}</td>
      <td>{getDisplayBalance(Number(statistic.refundFees), currency)}</td>
    </tr>
  );
};
