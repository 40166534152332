import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import StpAlert from 'src/components/vendor/StpAlert';
import configuration from 'src/configuration';
import { Application } from 'src/models';

export default function StackDown({
  stack,
}: {
  stack: Application.State['stack'];
}): ReactElement {
  return (
    <div style={{ display: 'inline-block' }}>
      {stack.localization === Application.BootPhase.UP ? (
        <LocalizedMessage />
      ) : (
        <h3>Oops, application stack is down.</h3>
      )}
      {configuration.devMode && <Debug stack={stack} />}
    </div>
  );
}

function LocalizedMessage(): ReactElement {
  const { t } = useTranslation();
  return (
    <StpAlert variant="error" title={t('stack:down.title')}>
      {t('stack:down.text')}
    </StpAlert>
  );
}

function Debug({ stack }: { stack: Application.State['stack'] }): ReactElement {
  const items: { name: string; value: Application.BootPhase }[] = Object.keys(
    stack,
  )
    .filter(name => name !== 'state')
    .map(name => ({ name, value: stack[name] }));

  return (
    <ul>
      {items.map(({ name, value }) => (
        <li key={name}>
          <strong style={{ marginRight: '10px' }}>{value}</strong>
          {name}
        </li>
      ))}
    </ul>
  );
}
