import { ReactElement, useMemo } from 'react';
import ApiProvider from 'src/components/Application/ApiProvider';
import Router from 'src/components/Application/Content/Router';
import LocalizationProvider from 'src/components/Application/LocalizationProvider';
import { AppStateProvider, useAppState } from 'src/components/appState';
import configuration from 'src/configuration';
import { Application } from 'src/models';
import { ApiClient } from 'src/services/api';
import { applyAllMocks } from 'src/services/api/mocks';

export default function Stack({
  renderContent,
  renderFallback,
}: {
  renderContent(): ReactElement;
  renderFallback(stack: Application.State['stack']): ReactElement;
}): ReactElement {
  const [state, update] = useAppState();

  const apiClient = useMemo(
    () => {
      const client = new ApiClient();

      if (configuration.devMode?.applyMocks) {
        applyAllMocks(client.axios);
      }

      return client;
    },
    // only onceContent
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const content = useMemo((): undefined | ReactElement => {
    if (state.stack.state === Application.BootPhase.UP) {
      return renderContent();
    } else {
      return;
    }
  }, [renderContent, state.stack.state]);

  const fallback = useMemo((): undefined | ReactElement => {
    if (state.stack.state !== Application.BootPhase.UP) {
      return renderFallback(state.stack);
    } else {
      return;
    }
  }, [renderFallback, state.stack]);

  return (
    <>
      <Router />
      <ApiProvider state={state} update={update} defaultClient={apiClient}>
        <LocalizationProvider state={state} update={update}>
          {content}
        </LocalizationProvider>
      </ApiProvider>
      {fallback}
    </>
  );
}

Stack.Test = function Test({
  stateRef,
  render,
}: {
  stateRef?: { current?: Application.State };
  render(state: Application.State, update: Application.Update): ReactElement;
}): ReactElement {
  const initialState = useMemo(
    (): Application.State => ({
      ...Application.defaultState(),
      ...stateRef?.current,
      settings: { ...stateRef?.current?.settings, locale: 'cimode' },
    }),
    // only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <AppStateProvider
      defaultState={initialState}
      onStateChange={(state: Application.State): void => {
        if (stateRef) {
          stateRef.current = state;
        }
      }}
    >
      <ConnectedTest render={render} />
    </AppStateProvider>
  );
};

function ConnectedTest({
  render,
}: {
  render(state: Application.State, update: Application.Update): ReactElement;
}): ReactElement {
  const [state, update] = useAppState();

  return (
    <LocalizationProvider state={state} update={update}>
      {render(state, update)}
    </LocalizationProvider>
  );
}
