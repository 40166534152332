import {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { Application } from 'src/models';
import { logger } from 'src/services/logger/logger';
import { useEffectOnConnected } from 'src/utils/customReactHooks';
import { connectToState } from 'src/components/appState';

interface StateProps {
  locale: string;
  configuration?: Application.Router.Configuration;
  route?: Application.Router.RouteState;
  update: Application.Update;
}

export default connectToState<StateProps>(
  (state, update) => ({
    locale: state.settings.locale,
    configuration: state.stack.router.configuration,
    route: state.route,
    update,
  }),
  Router,
);

function Router({
  locale,
  configuration,
  route,
  update,
}: StateProps): ReactElement {
  const onUpdateRouter = useCallback(
    (change: Partial<Application.State['stack']['router']>): void => {
      update(state => ({
        ...state,
        stack: {
          ...state.stack,
          router: {
            ...state.stack.router,
            ...change,
          },
        },
      }));
    },
    [update],
  );

  useEffectOnConnected(() => {
    try {
      onUpdateRouter({
        configuration: new Application.Router.Configuration(),
        state: Application.BootPhase.UP,
      });
    } catch (error) {
      onUpdateRouter({
        state: Application.BootPhase.DOWN,
      });
      logger.error('STP [ERR] Stack/Router', error);
    }
  });

  return (
    <>
      {configuration && (
        <SyncLocation
          locale={locale}
          configuration={configuration}
          route={route}
          update={update}
        />
      )}
    </>
  );
}

function SyncLocation({
  locale,
  configuration,
  route,
  update,
}: {
  locale: string;
  configuration: Application.Router.Configuration;
  route?: Application.Router.RouteState;
  update: Application.Update;
}): ReactElement {
  useLayoutEffect(() => {
    if (!route) {
      return;
    }
    const path = configuration.getFullPathForRoute(
      {
        page: route.page,
        route: route.route,
        params: route.params,
      },
      locale,
    );
    Application.Router.getHistory().replaceState({}, '', path);
  }, [configuration, locale, route]);

  const onPopState = useCallback((): void => {
    const newRoute = configuration.getRouteForLocation(
      Application.Router.getLocation(),
    );
    update(state => ({ ...state, route: newRoute }));
  }, [update, configuration]);

  const onPopStateRef = useRef(onPopState);
  useEffect(() => {
    onPopStateRef.current = onPopState;
  }, [onPopState]);

  useEffectOnConnected(() => {
    const callback = (): void => onPopStateRef.current();

    if (!route) {
      callback();
    }

    window.addEventListener('popstate', callback);
    return () => window.removeEventListener('popstate', callback);
  });

  return <></>;
}
