import { ReactElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PosFilterForm } from 'src/components/pages/Users/Pos/PosFilterForm';
import { PosRow } from 'src/components/pages/Users/Pos/PosRow';
import { useUrlParamsQuery } from 'src/components/RecordsTable/hooks';
import RecordsTable, { Query } from 'src/components/RecordsTable/RecordsTable';
import { InstallationsProvider } from 'src/contexts/InstallationsContext';
import { usePos } from 'src/services/hooks/usePos';
import * as Yup from 'yup';

export default function Pos(): ReactElement {
  const { t } = useTranslation();

  const searchFiltersSchema = Yup.object().shape({
    eshopId: Yup.string().default(''),
    storeId: Yup.string().default(''),
    poiId: Yup.string().default(''),
  });

  const buildInitialQuery = (params: Record<string, string>): Query => ({
    ...searchFiltersSchema.getDefaultFromShape(),
    sort: 'eshopId',
    ...params,
  });

  const [query, params, setQuery] = useUrlParamsQuery(buildInitialQuery);
  const { listing, onQuery } = usePos();

  const refetchData = useCallback(() => {
    onQuery(params);
  }, [onQuery, params]);

  useEffect(refetchData, [refetchData]);

  const headers = [
    { label: t('users:filter:common.projectId'), orderKey: 'eshopId' },
    { label: t('pos:table.terminalId') },
    { label: t('pos:table.poiId') },
    { label: t('pos:table.storeName') },
    { label: t('pos:table.storeId') },
    { label: t('pos:table.storeIdAdyen') },
    { label: t('users:table.common.action') },
  ];

  return (
    <InstallationsProvider value={{ refetchData }}>
      <RecordsTable<Pos>
        queryResult={listing}
        renderRow={(record: Pos): JSX.Element => (
          <PosRow pos={record} key={record.id} />
        )}
        filtersForm={<PosFilterForm />}
        headers={headers}
        query={query}
        filtersSchema={searchFiltersSchema}
        onQueryChange={setQuery}
      />
    </InstallationsProvider>
  );
}
