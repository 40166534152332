import { ReactElement } from 'react';
import { knownIcons } from './Icon';

export interface IconProps {
  icon: keyof typeof knownIcons;
  size?: 12 | 16 | 24;
  className?: string;
}

export default function StpIcon({
  icon,
  size,
  className,
}: IconProps): ReactElement {
  return knownIcons[icon]({ size: size ?? 16, className });
}
