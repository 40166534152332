import { ReactElement } from 'react';
import IndexTabs from 'src/components/pages/Users/Index/IndexTabs';
import Pos from 'src/components/pages/Users/Pos/Pos';

PosRoute.route = 'pos';

export default function PosRoute(): ReactElement {
  return (
    <>
      <IndexTabs />
      <Pos />
    </>
  );
}
