import ActiveRoute from 'src/components/routes/Overview/routes/ActiveRoute';
import BannedRoute from 'src/components/routes/Overview/routes/BannedRoute';
import IndexRoute from 'src/components/routes/Overview/routes/IndexRoute';
import PosRoute from 'src/components/routes/Overview/routes/PosRoute';
import ProcessingRoute from 'src/components/routes/Overview/routes/ProcessingRoute';
import RefundsRoute from 'src/components/routes/Overview/routes/RefunsdRoute';
import SuspendedRoute from 'src/components/routes/Overview/routes/SuspendedRoute';
import UnapprovedRoute from 'src/components/routes/Overview/routes/UnapprovedRoute';
import { Application } from 'src/models';

const routes: Application.Router.RouteComponent[] = [
  ActiveRoute,
  BannedRoute,
  IndexRoute,
  ProcessingRoute,
  SuspendedRoute,
  UnapprovedRoute,
  RefundsRoute,
  PosRoute,
];
export default routes;
