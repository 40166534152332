import { ResponseValues } from 'axios-hooks';
import { QueryResult } from 'src/services/api/types';

export function mapQueryResult<V, R>(
  response: ResponseValues<V, any, any>,
  mapper: (data: V) => R,
): QueryResult<R> {
  if (response.loading) {
    return { idle: false, processing: true };
  }
  if (response.error) {
    return { idle: false, processing: false, failed: true };
  }
  if (!response.data) {
    return { idle: true };
  }
  return {
    idle: false,
    processing: false,
    failed: false,
    data: mapper(response.data),
  };
}
