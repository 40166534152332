import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { TransactionTableRowProps } from 'src/components/pages/Users/common/common';
import { EshopNameColumn } from 'src/components/pages/Users/common/EshopNameColumn';
import { SwitchState } from 'src/components/pages/Users/common/SwitchState';
import { ServiceState } from 'src/consts/ServiceState';

export const SuspendRow: React.FC<TransactionTableRowProps> = ({
  installation,
}: TransactionTableRowProps): JSX.Element => {
  const { t } = useTranslation('users');

  return (
    <tr>
      <EshopNameColumn installation={installation} />
      <td>{moment(installation.updatedAt).format('DD.MM.YYYY HH:mm')}</td>
      <td>{installation.eshopInternals}</td>
      <td>{t(`table.common.services.${installation.service}`)}</td>
      <td>{installation.contactName}</td>
      <td>
        <strong>TODO:</strong> Tariff
      </td>
      <td>
        <SwitchState
          options={installation.allowedStates as ServiceState[]}
          installation={installation}
        />
      </td>
    </tr>
  );
};
