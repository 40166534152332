import { useTranslation } from 'react-i18next';
import React from 'react';

const NoMatch: React.FC = () => {
  const { t } = useTranslation('errors');
  return (
    <div className="error-wrap">
      <h1 className="std-header">{t('heading')}</h1>
      <p>{t('noMatch')}</p>
    </div>
  );
};

export default NoMatch;
