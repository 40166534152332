import { MouseEvent, ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useFormikContext } from 'formik';
import { logger } from 'src/services/logger/logger';

export default function FilterForm({
  visible,
  children,
}: {
  visible: boolean;
  children: ReactNode;
}): ReactElement {
  const { t } = useTranslation('filter');
  const { submitForm, resetForm } = useFormikContext();

  const handleSubmitInner = (e: MouseEvent): void => {
    e.preventDefault();
    submitForm().catch((e: unknown) => logger.error(e));
  };

  const handleReset = (e: MouseEvent): void => {
    e.preventDefault();
    resetForm();
    submitForm().catch((e: unknown) => logger.error(e));
  };

  if (!visible) {
    return <></>;
  }

  return (
    <Form id="statements-filter-form" className="dont-display-exit-confirm">
      <fieldset className="custom-overflow visible">
        <div className="custom-overflow visible">
          <div id="filter">
            <div className="filter-grid">{children}</div>
          </div>
          <div className="filter-buttons">
            <button
              title={t('filter')}
              className="btn btn-md btn-action submit-js"
              onClick={handleSubmitInner}
            >
              {t('filter')}
            </button>
            &ensp;
            <button
              title={t('clear')}
              className="btn btn-md btn-default"
              onClick={handleReset}
            >
              {t('clear')}
            </button>
          </div>
        </div>
      </fieldset>
    </Form>
  );
}
