import useAxios from 'axios-hooks';
import { useCallback } from 'react';
import { mapQueryResult } from 'src/services/api/hooks/helpers';
import { ListingResult, QueryParams } from 'src/services/api/types';

export function useRefunds(): {
  listing: ListingResult<Refund>;
  onQuery(params: QueryParams): void;
} {
  const [response, query] = useAxios<Refunds>(
    {
      url: 'v1/refunds',
    },
    { manual: true },
  );

  const onQuery = useCallback(
    (params: QueryParams): void => {
      query({ params }).catch(() => null);
    },
    [query],
  );

  return {
    listing: mapQueryResult(response, ({ count, items }) => ({
      count,
      records: items,
    })),
    onQuery,
  };
}
